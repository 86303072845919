import React from 'react';

class HomeSalatJanazaReq extends React.Component {
    render(){
        return (
            <div className="ftr_slat_janazah">
                <div className="container">
                    <div className="row">
                        <h1>SALAT AL-JANAZAH REQUEST</h1>
                        <div className="row">
                            <div className="col-md-9">
                                <p>“It is Allah Who gives you life, then gives you death, thenHe will gather you
                                    togather for the Day if Judgment about
                                    which there is no doubt”: But most men do not understand.</p>
                                <p>(Quran 45:26)</p>
                            </div>
                            <div className="col-md-3 align-right">
                                <div className="align_right"><a className="button btn_outline wd_180" href="#">REQUEST NOW</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeSalatJanazaReq;