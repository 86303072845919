import React from "react";
import RecentImgs1 from '../../Assets/imgs/recentImg1.png'
import RecentImgs2 from '../../Assets/imgs/recentImg2.png'
import RecentImgs3 from '../../Assets/imgs/recentImg3.png'
import RecentImgs4 from '../../Assets/imgs/recentImg4.png'

import {Link, withRouter} from "react-router-dom";
import moment from "moment";
import { serialize } from "../../utils/markdowntoreact";

function RecentNews(props) {
    const {news} = props;
    const newsData = news?.length ? news.slice(0, 4).map((el, i) => {
        const article = el?.article
        let formatDate = moment(el?.publishFrom).format("MMMM DD, YYYY");

        return <li key={i} className="nav-item" onClick={() => props.history.push({ 
            pathname: "/news-details/static-news-detail", 
            state: { news: article } 
        })}><a className="nav-link" href="#dashboard-1"
            role="tab" data-toggle="tab">
            <div className="v_list">
            <div className="v_list_thumb">
            <img className="vd_tumb" src={article?.imageMetaData?.imageUrl ?? ""} alt="videos" /></div>
            <div className="v_details">
            <div className="post_date"><i
            className="far fa-calendar"></i> {formatDate}
            </div>
            <h5>{article?.title}</h5>
            </div>
            <div className="clearfix"></div>
            </div>
            </a>
        </li>
    }) : null;
    
    return (
        <div className="sidebar_section news_details">
            <ul className="nav nav-pills nav-pills-icons tab_listing" role="tablist">
               
               {newsData}
               
                {/* <li className="nav-item"><a className="nav-link active" href="#dashboard-1"
                                            role="tab" data-toggle="tab">
                    <div className="v_list">
                        <div className="v_list_thumb">
                            <img className="vd_tumb" src={RecentImgs1} alt="videos" /></div>
                        <div className="v_details">
                            <div className="post_date"><i
                                className="far fa-calendar"></i> Oct 30, 2019
                            </div>
                            <h5>Bracing the Perfect Storm with ad Air of Calm</h5>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </a></li>

                <li className="nav-item"><a className="nav-link" href="#schedule-1"
                                            role="tab" data-toggle="tab">
                    <div className="v_list">
                        <div className="v_list_thumb"><img className="vd_tumb" src={RecentImgs2} alt="videos" /></div>
                        <div className="v_details">
                            <div className="post_date"><i
                                className="far fa-calendar"></i> Oct 30, 2019
                            </div>
                            <h5>Bracing the Perfect Storm with ad Air of Calm</h5>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </a></li>
                <li className="nav-item"><a className="nav-link" href="#tasks-1" role="tab"
                                            data-toggle="tab">
                    <div className="v_list">
                    <div className="v_list_thumb"><img className="vd_tumb" src={RecentImgs3} alt="videos" /></div>
                        <div className="v_details">
                            <div className="post_date"><i
                                className="far fa-calendar"></i> Oct 30, 2019
                            </div>
                            <h5>Bracing the Perfect Storm with ad Air of Calm</h5>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </a></li>
                <li className="nav-item"><a className="nav-link" href="#tasks-2" role="tab"
                                            data-toggle="tab">
                    <div className="v_list">
                    <div className="v_list_thumb"><img className="vd_tumb" src={RecentImgs4} alt="videos" /></div>
                        <div className="v_details">
                            <div className="post_date"><i
                                className="far fa-calendar"></i> Oct 30, 2019
                            </div>
                            <h5>Bracing the Perfect Storm with ad Air of Calm</h5>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </a></li>
           */}
            </ul>
        </div>
    );
}
export default withRouter(RecentNews);

