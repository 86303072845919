import React, { useState, useEffect } from 'react';
import BreadCrumbSection from "../BreadCrumbSection/BreadCrumbSection";
import RecentNews from "../RecentNews/RecentNews";
import Socialicons from "../Socialicons/Socialicons";
import Ad728X90 from "../Ad728X90/Ad728X90";
import HomePageWeeklyProgSection from "../HomePageWeeklyProgSection/HomePageWeeklyProgSection";
import ServicesList from "../ServicesList/ServicesList";
import Ad336X280 from "../Ad336X280/Ad336X280";
import Helmet from "react-helmet";
import LatestEventsSidebar from "../Common/LatestEventsSidebar.js";
import moment from 'moment';
import { VideoPlayerModal } from "master-react-lib";

import { getVideos, getNews, getOrganization } from "../Home/apis";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function LatestVideos(props) {

    const [videos, setvideos] = useState([])
    const [news, setnews] = useState([])
    const [loadMore, setloadMore] = useState(false)
    const [videoId, setVideoId] = useState(null)
    const [showVideo, setShowVideo] = useState(false)
    const [showFeaturePages, setShowFeaturePages] = useState({});
    const [facebookUrl, setFacebookurl] = useState('');
    const [youtubeUrl, seYoutubeUrl] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            try {
                const ordData = await getOrganization(orgId);
                const orgSocials = ordData.data?.body?.data?.orgSocials
                setFacebookurl(orgSocials.facebook);
                seYoutubeUrl(orgSocials.youtube);

                if(ordData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages?.videos?.status === "inactive") {
                    props.history.push("/");
                } else {
                    setShowFeaturePages(ordData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages);
                    let resVideo = await getVideos(orgId, "deenweb")
                    resVideo = resVideo?.data?.body?.data ?? [];
                    resVideo = resVideo.filter(({ status, publishTo, publishFrom }) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
                    setvideos(resVideo)
                }

            } catch (err) {
                console.log(err)
            }

            try {
                let resNews = await getNews(orgId, "deenweb")
                resNews = resNews?.data?.body?.data ?? [];
                resNews = resNews.filter(({ status, publishTo, publishFrom }) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
                setnews(resNews)

            } catch (err) {
                console.log("here", err)
            }
        }
        getData();
    }, [])

    const getVideoId = (videoId) => {
        if (videoId) {
            setVideoId(videoId)
            setShowVideo(true)
        }

    }

    const videosData = videos?.length ? videos.slice(0, loadMore ? 100 : 10).map(({ video }, i) => {
        let formatDate = moment(video?.createdAt).format("MMMM DD, YYYY");
        let videoId = video?.link?.split("/embed/")?.[1] ? video.link.split("/embed/")[1] : null;

        return <div key={i} className="col-lg-4 col-md-6 col-sm-12" onClick={() => getVideoId(videoId)}>
            <div className="vd_list">
                <img className="img_responsive" src={`https://img.youtube.com/vi/${videoId}/sddefault.jpg`} />
                {/* <iframe src={video?.link ?? ""} style={{ width: "100%" }}></iframe> */}



                <div className="vd_list_details">
                    <div className="post_date"><i
                        className="far fa-calendar"></i> {formatDate}
                    </div>
                    <h4>{video.title}</h4>
                </div>
            </div>
        </div>
    }) : null;

    const videoProps = {
        facebook : {facebookUrl},
        youtube: {youtubeUrl}
    }
    return (
        <div>

            <div className="fixed_bg"></div>
            <div className="wrapper body">
                {/*------- Update website title -------*/}
                <Helmet>
                    <title>Darul Jannah - Videos | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="LATEST VIDEOS" b_link="VIDEOS" />

                {/*-------------- EVENTS AND RECENT NEWS ------------*/}
                <div className="section">
                    <div className="container">
                        <div className="news_content">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    <div className="sec_title_group">
                                        <h1 className="section_title">DARUL JANNAH VIDEOS</h1>
                                    </div>

                                    <div className="video_container">

                                        <div className="video_player">
                                            <iframe src={videos?.[0]?.video?.link ?? ""} style={{ width: "100%", minHeight: "500px" }}></iframe>
                                        </div>

                                        <div className="vd_detail">

                                            <h2>{videos?.[0]?.video?.title}</h2>
                                            <span>by <label>ADMIN PANEL</label></span>
                                            <span>|</span>
                                            {/* <span>1486 views</span> */}
                                            <span><i className="far fa-calendar"></i> {videos?.[0]?.video?.createdAt ? moment(videos?.[0]?.video?.createdAt).format("MMM DD, YYYY") : null}</span>
                                        </div>

                                    </div>

                                </div>


                                <div className="col-lg-4 col-md-12 news_details">
                                    {showFeaturePages?.articles?.enablePage && 
                                        <>
                                            <div className="sec_title_group">
                                                <h2 className="section_title">RECENT NEWS</h2>
                                            </div>
                                            <RecentNews news={news} />
                                        </>
                                    }

                                    <div className="sidebar_section">
                                        <div className="sidebar_title">
                                            <h2>GET CONNECTED</h2>
                                        </div>
                                        <Socialicons orgSocials = {videoProps} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="section section_2">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-8 col-md-12">


                                <div className="hr_tab">

                                    {/* <ul className="nav nav-pills nav-pills-icons flex_tab" role="tablist">

                                        <li className="nav-item">
                                            <a className="nav-link active show  firstchild" href="#recent" role="tab"
                                               data-toggle="tab" aria-selected="true">
                                                RECENT
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#most_viewed" role="tab" data-toggle="tab"
                                               aria-selected="false">
                                                MOST VIEWED
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#most_viewed" role="tab" data-toggle="tab"
                                               aria-selected="false">
                                                MOST LIKED
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#most_viewed" role="tab" data-toggle="tab"
                                               aria-selected="false">
                                                EID VIDEOS
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#most_viewed" role="tab" data-toggle="tab"
                                               aria-selected="false">
                                                KHUTBAH
                                            </a>
                                        </li>
                                    </ul> */}


                                    <div className="tab-content tab-space">
                                        <div className="tab-pane active show" id="recent">
                                            <div className="row">
                                                {videosData}
                                                <VideoPlayerModal
                                                    isOpen={showVideo}
                                                    onClose={() => setShowVideo(false)}
                                                    vidoeID={videoId}
                                                />
                                            </div>

                                            {!loadMore && videos?.length > 6 ? <div className="more_view show_more"
                                                onClick={() => {
                                                    setloadMore(true)
                                                }}>
                                                <a href="javascript:void(0);">SHOW MORE</a>
                                            </div> : null}

                                        </div>



                                        <div className="tab-pane" id="most_viewed">

                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="vd_list">
                                                        <img className="img_responsive" src="assets/img/vd_list_2.png" />
                                                        <div className="vd_list_details">
                                                            <div className="post_date"><i
                                                                className="far fa-calendar"></i> Oct 30, 2019
                                                            </div>
                                                            <h4>Simples Steps to Jannah (Paradise)</h4>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                            </div>


                            <div className="col-lg-4 col-md-12">
                                <div className="sidebar">
                                    {showFeaturePages?.events?.enablePage && 
                                        <LatestEventsSidebar news = {news}/>
                                    }

                                    {/*-------------- Ad ------------------*/}
                                    <Ad336X280 />

                                </div>

                            </div>


                        </div>
                    </div>
                </div>


                {/*-------------------------- END VIDEOS TAB -----------------------*/}

                {/*----------------------- WEEKLY PROGRAM SECTION ------------------ */}
                <HomePageWeeklyProgSection /> 
                {/*----------------------- Services List ----------------------------*/}
                {showFeaturePages?.services?.enablePage && 
                    <ServicesList />
                }

                {/* <div className="section">
                    <Ad728X90/>
                </div> */}


                <div className="footer_space"></div>


            </div>
        </div>
    );
}

export default LatestVideos;