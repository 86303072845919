import React, { useEffect, useState } from "react";
import { getSliders } from "../Home/apis";
import { Link } from 'react-router-dom';
import { serialize } from "../../utils/markdowntoreact";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function HomeSlider() {
    const [sliders, setsliders] = useState([])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            try {
                const resAnn = await getSliders(orgId)
                let resData = resAnn.data.body.data;
                resData = resData.filter(({website}) => Object.keys(website).length > 0 && website.status === "Public" && (new Date(website.publishTo) > new Date()) && (new Date(website.publishFrom) < new Date()));
                if(resData.length > 0) {
                    resData = resData.sort((a, b) => a.order - b.order);
                }
                setsliders(resData)

            } catch (err) {
                console.log(err)
            }
        }
        getData();
    }, []);

    const checkArabic = (text) => {
        let pattern = /[\u0600-\u06FF]/;
        return pattern.test(text);
    }

    const slidersData = sliders?.length ? sliders.map((slider, i) => {
        let styleCss = {};
        let x = slider?.description && JSON.parse(slider.description);
        let editor;
        if (x) {
            editor = { children: [...x] }
        }
        
        let description = slider?.description && slider.description?.includes('"type":') ? serialize(editor) : slider?.description;
        description = description.replace("<p>", '').replace("</p>", '');
        let rtl = description.length > 0 ? checkArabic(description) : null;

        if(rtl) {
            styleCss = {
                direction: 'rtl',
                textAlign: 'right',
                width: '100%'
            }
        }
        return <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
            <div className="view">
                <div className="BannerAlfaBG"></div>
                <img className="d-block w-100 main-banner" style={{ minHeight: '400px' }} src={slider?.imageMetaData?.imageUrl ?? ""} alt="First slide" />
                <div className="mask rgba-black-light"></div>
            </div>
            <div className="carousel-caption" style = {{maxWidth: '1100px', width: '100%'}}>
                <h3 className="h3-responsive"  style = {{display: '-webkit-box', webkitLineClamp: '2', webkitBoxOrient: 'vertical', overflow: 'hidden',textOverflow: 'ellipsis', ... styleCss}}>{slider.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: description}} style = {{display: '-webkit-box', webkitLineClamp: '2', webkitBoxOrient: 'vertical', overflow: 'hidden',textOverflow: 'ellipsis', ... styleCss}}>
                    
                </p>
                {slider?.button?.actionButton && <a className="banner_readmore" href={slider?.button?.buttonLink ?? ""}>{slider?.button?.buttonLabel ?? ""}
                    <ArrowRightAltIcon />
                </a>}
            </div>
        </div>
    }) : null;

    return (
        <div className="home_slider">
            
            <div id="carousel-example-2" className="carousel slide carousel-fade" data-ride="carousel">
                <div className="carousel-inner" role="listbox">
                    {slidersData}
                </div>

                <ol className="carousel-indicators">
                    {sliders?.length ? sliders.map((slider, i) => {
                        let styleCss = {};
                        let rtl = checkArabic(slider.title);
                        console.log("RTLs: ", rtl);
                        if(rtl) {
                            styleCss = {
                                direction: 'rtl',
                                textalign: 'right',
                                width: '100%'
                            }
                        }
                        return <li key={i} data-target="#carousel-example-2" data-slide-to={i} className={i === 0 ? "active" : ""} style = {{... styleCss}}>{slider.title}
                        </li>
                    }) : null}

                </ol>
            </div>
        </div>
    );
}

export default HomeSlider;
