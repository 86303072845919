import React, { useEffect, useState } from 'react';
import VideosTab from '../VideosTab/VideosTab';
import { getVideos } from "../Home/apis";
import moment from "moment";
import { Link } from 'react-router-dom';
import { serialize } from "../../utils/markdowntoreact";
import Grid from '@material-ui/core/Grid';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;


function HomePageVideosSection(props) {
    const [videos, setvideos] = useState([])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            try {
                let resVideo = await getVideos(orgId, "deenweb")
                resVideo = resVideo?.data?.body?.data ?? [];
                resVideo = resVideo.filter(({ status, publishTo, publishFrom }) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
                resVideo = resVideo?.length ? resVideo.filter((el) => el.featured === true) : [];
                if(resVideo.length > 0) {
                    resVideo = resVideo.sort((a, b) => a?.video?.order - b?.video?.order );
                }
                // console.log("videos", resVideo);
                setvideos(resVideo)

            } catch (err) {
                console.log(err)
            }
        }
        getData();
    }, [])

    return (
        <div className="section">
            {videos?.length ? <div className="container">



                <div className="video_container">

                    <Grid container spacing={3}>

                        <Grid item md={12} sm={12} xs={12}>
                            <div className="sec_title_group space_20">
                                <h1 className="section_title">DARUL JANNAH VIDEOS</h1>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={3}>

                            <Grid item md={9} sm={9} xs={12}>
                                    <div className="video_player">
                                        <iframe src={videos?.[0]?.video?.link ?? ""} style={{ width: "100%", minHeight: "500px" }}></iframe>
                                        {/* <img className="img_responsive" src="assets/img/player.png" /> */}
                                    </div>
                                    <div className="vd_detail">
                                        <h2>{videos?.[0]?.video?.title}</h2>
                                        <span>by
                                            <label>ADMIN PANEL</label>
                                        </span> <span>|</span>
                                        {/* <span>1486 views</span> */}
                                        <span><i className="far fa-calendar"></i> {videos?.[0]?.video?.createdAt ? moment(videos?.[0]?.video?.createdAt).format("MMM DD, YYYY") : null}</span>
                                    </div>
                                </Grid>

                                <Grid item md={3} sm={3} xs={12}>
                                    
                                        <VideosTab videos={videos}  getVideoId = {props.getVideoId}/>
                                    
                                </Grid>
                            </Grid>
                        </Grid>



                    </Grid>
                </div>

                

                <div className="more_view"><Link to="/latest-videos">VIEW ALL VIDEOS</Link></div>

            </div> : null}
        </div>
    );
}

export default HomePageVideosSection;