import React from 'react';
import moment from "moment";
import { Link } from 'react-router-dom';
import { serialize } from "../../utils/markdowntoreact";


function VideosTab({videos, getVideoId}) {

    const videosData = videos?.length ? videos.slice(1, 3).map(({video}, i) => {
        let formatDate = moment(video?.createdAt).format("MMM DD, YYYY");
        let videoId = video?.link?.split("/embed/")?.[1] ? video.link.split("/embed/")[1] : null;
        return <div className="vd_list" onClick={() => getVideoId(videoId)}>
        {/* <iframe src={video.link} style={{width: "100%"}}></iframe> */}
        <img className="img_responsive" src={`https://img.youtube.com/vi/${videoId}/sddefault.jpg`} />
        <div className="vd_list_details">
            <div className="post_date"><i className="far fa-calendar"></i> {formatDate} </div>
            <h4>{video.title}</h4>
        </div>
        </div>
    }) : null;

    return (
        <div className="videoListContainer">
            <div className="tab-pane active show" id="recent">
                
                {/* <div className="vd_list">
                    <img className="img_responsive" src="assets/img/vd_list_1.png" />
                    <div className="vd_list_details">
                        <div className="post_date"><i className="far fa-calendar"></i> Oct 30, 2019 </div>
                        <h4>Simples Steps to Jannah (Paradise)</h4>
                    </div>
                </div>
                <div className="vd_list">
                    <img className="img_responsive" src="assets/img/vd_list_2.png" />
                    <div className="vd_list_details">
                        <div className="post_date"><i className="far fa-calendar"></i> Oct 30, 2019 </div>
                        <h4>Sincere Actions (Khutbah) - Imam Siraj...</h4>
                    </div>
                </div> */}
            </div>
            <div className="tab-pane" id="most_viewed">
                {videosData}
            </div>
        </div>
    );
}

export default VideosTab;