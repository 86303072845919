import React, {useState} from 'react';
import './App.css';
import ReactDOM from 'react-dom';
import Home from "./Components/Home/Home";
import {BrowserRouter, Route, Link, Switch } from 'react-router-dom';
import HeaderNotificationSlider from "./Components/HeaderNotificationSlider/HeaderNotificationSlider";
import NavigationBar from "./Components/NavigationBar/NavigationBar";
import AboutUs from "./Components/AboutUs/AboutUs";
import FooterCommon from "./Components/FooterCommon/FooterCommon";
import EventList from "./Components/EventList/EventList";
import EventDetails from "./Components/EventDetails/EventDetails";
import Faq from "./Components/FAQ/Faq";
import LatestVideos from "./Components/LatestVideos/LatestVideos";
import NewsList from "./Components/NewsList/NewsList";
import NewsDetails from "./Components/NewsDetails/NewsDetails";
import Eid from "./Components/Eid/Eid";
import DeathReportForm from "./Components/FormComponents/DeathReportForm/DeathReportForm";
import GraveyardForm from "./Components/FormComponents/GraveyardForm/GraveyardForm";
import SponsorConfirmation from "./Components/FormComponents/SponsorConfirmation/SponsorConfirmation";
import IftarSponsorForm from "./Components/FormComponents/IftarSponsorForm/IftarSponsorForm";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import TermsPolicies from "./Components/TermsPolicies/TermsPolicies";
import SuccessAlert from './Components/Common/SuccessAlert';
import PageNotFound from './Components/PageNotFound/';
import { VideoPlayerModal } from "master-react-lib";



export default function App() {
    const base_url = 'http://localhost:3000/';
    const [open, setopen] = useState(false);
    const [email, setemail] = useState("");
    const [showFooterVideo, setShowFooterVideo] = useState(false);
    const [footerVideoId, setfooterVideoId] = useState(null)
    const showAlert = (value, email) => { 
        setopen(value)
        setemail(email)

        setTimeout(() => {
            setopen(false)
        }, 5000);
    }

    const getFooterVideoId = (id) => {
        if(id) {
            setfooterVideoId(id);
            setShowFooterVideo(true);
        }
    }
    return (
        <BrowserRouter>
            {open && <SuccessAlert msg={`Thanks! We will shortly send a confirmation email to ${email} if not yet subscribed.`} open={open} setOpen={setopen} />}
            <VideoPlayerModal
                isOpen={showFooterVideo}
                onClose={() => setShowFooterVideo(false)}
                vidoeID={footerVideoId}
            />
            <HeaderNotificationSlider/>
            <NavigationBar base_url={base_url}/>
            
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/about-us" component={AboutUs}/>
                <Route path="/event-list" component={EventList}/>
                <Route path="/FAQ" component={Faq} />
                <Route path="/latest-videos" component={LatestVideos} />
                <Route path="/news-list" component={NewsList} />
                <Route path="/eid" component={Eid} />
                {/*------------------ Forms Components --------------------*/}
                <Route path="/report-a-death" component={DeathReportForm} />
                <Route path="/reserve-your-today" component={GraveyardForm} />
                <Route path="/sponsor-confirmation" component={SponsorConfirmation} />
                <Route path="/iftar-sponsor-form" component={IftarSponsorForm} />

                <Route path="/event-details/:slug" component={EventDetails}/>
                <Route path="/news-details/:slug" component={NewsDetails}/>

                {/*------------------ Privacy Policy --------------------*/}

                <Route path="/PrivacyPolicy" component={PrivacyPolicy}/>
                <Route path="/TermsPolicies" component={TermsPolicies}/>

                <Route component={PageNotFound}/>

                {/*<Route path="/posts" component={Posts} />
              <Route path="/post-details/:id" component={PostDetails} />*/}
            </Switch>
            

            <FooterCommon showAlert={showAlert} getFooterVideoId = {getFooterVideoId}/>
        </BrowserRouter>

    );
}