import React from 'react';
import Helmet from "react-helmet";
import BreadCrumbSection from "../../BreadCrumbSection/BreadCrumbSection";

function SponsorConfirmation() {
    return (
        <div className="wrapper">

            <Helmet>
                <title>Darul Jannah - Sponsor Confirmation | Powered by DEENCONNECT</title>
            </Helmet>
            {/*-------------- BREADCRUMB SECTION ----------------*/}
            <BreadCrumbSection title="SPONSOR CONFIRMATION" b_link="CONFIRMATION"/>

            <div className="page_content">
                <div className="gv_content ">
                    <div className="cmf_icon"><i className="fas fa-check"></i></div>

                    <div className="comfirm_message">
                        <p>Your Request Submitted Successfully</p>
                    </div>

                    <p className="sub_text">Your request to sponsor iftar was submitted successfully. One of our team
                        members will reach you shortly to confirm the details.</p>
                    <button className="btn_submit">HOMEPAGE</button>
                    <button className="btn_submit">BACK TO RAMADAN PAGE</button>

                </div>
            </div>


            <div className="footer_space"></div>

        </div>
    );
}

export default SponsorConfirmation;