import React, { useEffect, useState } from "react";
import { getServices } from "../Home/apis";
import { Link } from 'react-router-dom';
import { serialize } from "../../utils/markdowntoreact";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import Ad728X90 from '../Ad728X90/Ad728X90'


const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function ServicesList() {
    const [services, setservices] = useState([])

    useEffect(() => {
        const getData = async () => {
            try {
                let resService = await getServices(orgId, "deenweb")
                resService = resService?.data?.body?.data ?? [];
                resService = resService?.length ? resService.filter((el) => el.featured === true && el.status === "Public" && (new Date(el.publishTo) > new Date()) && (new Date(el.publishFrom) < new Date())) : [];
                if(resService.length > 0) {
                    resService = resService.sort((a, b) => a?.service?.order - b?.service?.order);
                }
                setservices(resService)
    
              } catch (err) {
                console.log(err)
              }
        }
        getData();
    }, [])
    
    const servicesData = services?.length ? services.slice(0, 4).map(({service}, i) => {
        let x = service?.description && JSON.parse(service?.description);
        let editor;
        if(x) {
         editor = { children: [ ...x ] }
        }

        const description = service?.description && service?.description?.includes('"type":') ? serialize(editor) : service?.description;
        console.log(description?.length)

        return <div key={i} className="col-lg-3 col-md-6 col-sm-12">

            <Link className="services_list" to={{ pathname: "/about-us", state: { tab: service?._id } }}>
                <div className="sr_img">
                    <img className="img_responsive" src={service?.imageMetaData?.imageUrl} /></div>
                <div className="sr_details">
                    <h3>{service?.name}</h3>
                    <p dangerouslySetInnerHTML={{ __html: description?.substring(0, 80) + "..." }}></p>
                    <div className="read_more">VIEW DETAILS <ArrowRightAltIcon /></div></div>
            </Link>
        </div>
    }) : null;

    return (
        <div className="section">
            {services?.length ? <div className="container">
                <div className="sec_title_group">
                    <h1 className="section_title">OUR SERVICES FOR THE COMMUNITY</h1>
                </div>
                <div className="services_container">
                    <div className="row">
                    {servicesData}
                    </div>
                    <div className="more_view">
                        <Link to={{ pathname: "/about-us", state: { tab: 1 } }}>
                            VIEW ALL SERVICES
                        </Link>
                    </div>
                </div>
            </div> : null }

            <Ad728X90/>

        </div>
    );
}

export default ServicesList;