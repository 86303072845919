import React from 'react';

function TabList({tab, services}) {
    console.log("services", services)

        const servicesData = services?.length ? services.map(({service}, i) => {
        return <li key={i} className="nav-item">
                    <a className={`nav-link ${service?._id === tab ? "active show" : ""}`} href={`#dashboard-${i + 1}`} role="tab"
                        data-toggle="tab" aria-selected="false">
                        {service?.name}
                    </a>
                </li>
    }) : null;


            return (  
                <div className="tab_list">
                        <ul className="nav nav-pills nav-pills-icons flex-column" role="tablist">

                            {servicesData}

                            {/* <li className="nav-item">
                                <a className="nav-link active show" href="#dashboard-1" role="tab"
                                   data-toggle="tab" aria-selected="false">
                                    DAILY AND JUMU'AH PRAYERS
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#schedule-2" role="tab" data-toggle="tab"
                                   aria-selected="true">
                                    RAMADAN IFTAR & TARAWIH
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#schedule-3" role="tab" data-toggle="tab"
                                   aria-selected="true">
                                    EID PRAYER
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#schedule-4" role="tab" data-toggle="tab"
                                   aria-selected="true">
                                    MONTHLY FAMILY NIGHT
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#schedule-5" role="tab" data-toggle="tab"
                                   aria-selected="true">
                                    HAJJ SEMINAR
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#schedule-6" role="tab" data-toggle="tab"
                                   aria-selected="true">
                                    WEEKEND & SUMMER SCHOOL
                                </a>
                            </li> */}
                            
                        </ul>
                    </div>
        );
}

export default TabList;


