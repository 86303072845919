import React from 'react';

function BreadCrumbSection(props) {
    return (
        <div className="page_title">
    	<span>
            <h1>{props.title}</h1>
            <div className="path">
            	<li>HOME</li>
            	<li><i className="fas fa-chevron-right"></i></li>
                <li>{props.b_link}</li>
            </div>
        </span>
        </div>
    );
}

export default BreadCrumbSection;