import React from 'react';
import axios from 'axios';

const TEST_SITE_KEY = process.env.REACT_APP_CAPTCHA_KEY;
const orgApiKey = process.env.REACT_APP_ORGANIZATION_API_KEY;

class FooterSubscribeNewsletter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            captcha: '',
            newsLetterData: {
                name: '',
                email: '',
                orgId: process.env.REACT_APP_ORGANIZATION_ID,
            },
        }
    }

    componentDidMount() {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${TEST_SITE_KEY}`
        document.body.appendChild(script)
    }

    changeHandler = (e) => {
            e.persist();
            this.setState(prevState => ({
                newsLetterData: {
                    ...prevState.newsLetterData,
                    [e.target.name]: e.target.value
                }
            }));
    }


    submitHandler = (e) => {
        e.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(TEST_SITE_KEY, { action: 'submit' }).then(token => {
              console.log("token", token)
              if(token) {
                let { name, email } = this.state.newsLetterData;
                if (name && email) {
                    name = name?.split(/ (.*)/s)
                    axios
                        .post(
                            process.env.REACT_APP_BASEURL_DEV_API + '/dw-public/newsLetter', { 
                                ...this.state.newsLetterData,
                                source: 'Website', 
                                name: { firstName: name[0], lastName: name[1] } 
                            }, {
                                headers: {
                                  token: orgApiKey
                                }
                            }
                        )
                        .then(res => {
                            this.props.showAlert(true, email)
                            localStorage.setItem("contactMail", email)
                            console.log('contact ', res);
                            this.setState({
                             newsLetterData: {
                                name: '',
                                email: '',
                                orgId: process.env.REACT_APP_ORGANIZATION_ID,
                            },
                            })
                        })
                        .catch(err => console.log('err', err))
                }
              }
            });
          });
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render(){

        const { newsLetterData } = this.state;
        const { name, email } = newsLetterData;
        let disableBtn = name && this.validateEmail(email) ? false : true;


        return (
            <div className="col-md-4 ftr_blog">
                <h1 className="ftr_blog_title">SUBSCRIBE FOR NEWSLETTER</h1>
                <p>SUBSCRIBE NOW for the latest Reads/Views, articles, vidoes & much more from our awesome
                    website!</p>
                <div className="ftr_form">
                    <form>
                        <input className="field" type="text" 
                            placeholder="Full Name" 
                            name="name"
                            onChange={this.changeHandler}
                            value={newsLetterData.name}
                        />
                        <input className="field" type="email" 
                            placeholder="email@provider.com"
                            name="email"
                            onChange={this.changeHandler}
                            value={newsLetterData.email} 
                        />
                        {/* <div className="form-check no_robot">
                            <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" defaultValue="" />
                                I’m not a robot
                                <span className="form-check-sign">
                                    <span className="check"></span>
                                </span>
                            </label>
                        </div> */}
                        <button type="submit" className={`submit_btn buttonFilled ${disableBtn ? "disabled" : ""}`} onClick={this.submitHandler} disabled={disableBtn}>SUBSCRIBE NOW</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default FooterSubscribeNewsletter;