import React from 'react';
import SidebarAdd from '../../Assets/imgs/SidebarAdd.png'

function Ad336X280(props) {
    var baseUrl = '';
    if(props.base_url){
        baseUrl = props.base_url;
    }
    return (
        <div className="home_ad_sidebar">
            <a href={localStorage.getItem("donationUrl") ?? '#'} target="blank">
                <img src={SidebarAdd}  className="addImg"/>
            </a>
        </div>
    );
}

export default Ad336X280;
