import React, { useEffect, useState } from 'react';
import BreadCrumbSection from '../BreadCrumbSection/BreadCrumbSection';
import HomePageWeeklyProgSection from "../HomePageWeeklyProgSection/HomePageWeeklyProgSection";
import ServicesList from "../ServicesList/ServicesList";
import SpeakerSlider from "../SpeakerSlider/SpeakerSlider";
import Socialicons from "../Socialicons/Socialicons";
import RecentNews from "../RecentNews/RecentNews";
import Helmet from "react-helmet";
import moment from "moment";
import { serialize } from "../../utils/markdowntoreact";

import Ad336X280 from '../Ad336X280/Ad336X280'
import Ad728X90 from '../Ad728X90/Ad728X90'
import { getOrganization } from '../Home/apis';


var baseUrl = 'http://localhost:3000/';
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function EventDetails(props) {
    const [event, setevent] = useState({})
    const [news, setNews] = useState([])
    const [showServices, setShowService] = useState(true);
    const [facebookUrl, setFacebookurl] = useState('');
    const [youtubeUrl, seYoutubeUrl] = useState('');

    useEffect(() => {

        const getData = async () => {
            try {
                const resAnn = await getOrganization(orgId)
                const resData = resAnn?.data?.body?.data ?? {};
                setFacebookurl(resData.orgSocials.facebook);
                seYoutubeUrl(resData.orgSocials.youtube)

                console.log("orgSocials Events: ", resData.orgSocials)
              } catch (err) {   
                console.log(err)
              }
        }
        getData();
        
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        if (props?.location?.state?.event) {
            setevent(props?.location?.state?.event)

        }
        if (props?.location?.state?.news) {
            setNews(props?.location?.state?.news)

        }
        if (!props?.location?.state?.showServices) {
            setShowService(props?.location?.state?.showServices)

        }
        
    }, [])

    let x = event?.description && JSON.parse(event?.description);
    let editor;
    if (x) {
        editor = { children: [...x] }
    }
    const description = event?.description && event?.description?.includes('"type":') ? serialize(editor) : event?.description;
    let startDateTime = moment(event?.eventStart?.startAt);
    let endDateTime = moment(event?.endAt);

    let formatDate = startDateTime.format("LLLL");
    let formatEndDate = endDateTime.format("LLLL");

    let formatTime = startDateTime.format("hh:mm A");
    let formatEndTime = endDateTime.format("hh:mm A");
    if (startDateTime?.format("DD MMMM, YYYY") === endDateTime?.format("DD MMMM, YYYY")) {
        console.log("Here")
        formatEndDate = null;
    }


    const eventDetailsProps = {
        facebook : {facebookUrl},
        youtube: {youtubeUrl}
    }

    console.log("eventDetailsProps: ", eventDetailsProps)
    return (
        <div>
            <div className="fixed_bg"></div>
            <               div className="wrapper body">
                {/*------- Update website title -------*/}
                <Helmet>
                    <title>Darul Jannah - Event Details | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="EVENT DETAILS" b_link="EVENTS DETAIL" />

                {/*------------- EVENT DETAILS ------------------*/}

                <div className="section">
                    <div className="container">
                        <div className="news_content">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    <div className="sec_title_group">
                                        <h1 className="section_title">{event?.title}</h1>
                                    </div>

                                    <div className="event_details">
                                        <img className="card_event img_responsive" src={event?.imageDetails?.imageUrl ?? ""} />
                                        <div className="card_details">
                                            <div dangerouslySetInnerHTML={{ __html: description }}></div>

                                            <div className="sidebar_social">
                                                <a href="https://www.facebook.com/DarulJannahMasjid/" target="blank"><i className="fab fa-facebook-f"></i></a>
                                                {/* <a href="#"><i className="fab fa-twitter"></i></a>
                                                            <a href="#"><i className="fab fa-youtube"></i></a> */}
                                            </div>

                                        </div>
                                        <div className="clearfix"></div>
                                    </div>

                                    <div className="ev_detail_list">
                                        <h2>EVENT DETAILS</h2>

                                        <li><span><i className="far fa-calendar"></i></span>{formatEndDate ? `${formatDate}  To   ${formatEndDate}` : formatDate}
                                        </li>
                                        <li><span><i className="far fa-clock"></i></span> {formatTime} TO {formatEndTime} {event?.eventZone}</li>
                                        <li><span><i className="fas fa-map-marker-alt"></i></span>
                                            {event?.location?.address ? event.location.address.line1 + ", " + (event?.location?.address?.city ?? "") + ", " + (event?.location?.address?.state ?? "") + " " + (event?.location?.address?.postalCode ?? "") : ""}
                                            {/* 722 CHURCH AVE, BROOKLYN, NY 11218 */}
                                        </li>

                                    </div>

                                    {/*------------- SPEAKERS SLIDER ----------------*/}
                                    <SpeakerSlider event={event} />
                                    {/*------------- SPEAKER ENDS -------------------*/}

                                </div>


                                {/*------------------------ Sidebar start ----------------------*/}
                                <div className="col-lg-4 col-md-12">
                                    {news && news.length > 0 &&
                                        <div className="sec_title_group">
                                            <h2 className="section_title">RECENT NEWS</h2>
                                            {/*------------------ RECENT NEWS COMPONENT ------------------*/}
                                            <RecentNews news={news} />

                                        </div>
                                    }

                                    <div className="sidebar_section">
                                        <div className="sidebar_title">
                                            <h2>GET CONNECTED</h2>
                                        </div>
                                        { eventDetailsProps && <Socialicons orgSocials = {eventDetailsProps}/> }
                                    </div>

                                    <Ad336X280 />



                                </div>

                            </div>

                        </div>


                    </div>
                </div>

                {/*----------------------- WEEKLY PROGRAM SECTION ------------------ */}
                <HomePageWeeklyProgSection />

                {/*----------------------- Services List ----------------------------*/}
                {showServices &&
                    <ServicesList />
                }

                {/* <div className="section">
                <Ad728X90 base_url={baseUrl}/>
            </div> */}

                <div className="footer_space"></div>


            </div>

        </div>
    );
}

export default EventDetails;