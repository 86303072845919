import React, { useState, useEffect } from 'react';
import BreadCrumbSection from "../BreadCrumbSection/BreadCrumbSection";
import { Link } from "react-router-dom";
import RecentNews from "../RecentNews/RecentNews";
import Socialicons from "../Socialicons/Socialicons";
import Ad728X90 from "../Ad728X90/Ad728X90";
import HomePageWeeklyProgSection from "../HomePageWeeklyProgSection/HomePageWeeklyProgSection";
import ServicesList from "../ServicesList/ServicesList";
import Ad336X280 from "../Ad336X280/Ad336X280";
import Helmet from "react-helmet";
import { getNews, getOrganization } from "../Home/apis";
import { serialize } from "../../utils/markdowntoreact";
import moment from 'moment';
import LatestVideosSidebar from "../Common/LatestVideosSidebar.js"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { VideoPlayerModal } from "master-react-lib";


const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function NewsList(props) {
    const [news, setnews] = useState([])
    const [videoId, setVideoId] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [showFeaturePages, setShowFeaturePages] = useState({});
    const [facebookUrl, setFacebookurl] = useState('');
    const [youtubeUrl, seYoutubeUrl] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        if (props?.location?.state?.news) {
            setnews(props?.location?.state?.news)
        } else {
            const getData = async () => {
                try {
                    const ordData = await getOrganization(orgId);
                    const orgSocials = ordData.data?.body?.data?.orgSocials
                    setFacebookurl(orgSocials.facebook);
                    seYoutubeUrl(orgSocials.youtube)
                    if (ordData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages?.articles?.status === "inactive") {
                        props.history.push("/");
                    } else {
                        setShowFeaturePages(ordData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages);
                        let resNews = await getNews(orgId, "deenweb");
                        resNews = resNews?.data?.body?.data ?? [];
                        resNews = resNews.filter(({ status, publishTo, publishFrom }) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
                        setnews(resNews);
                    }
                } catch (err) {
                    console.log("here", err)
                }
            }
            getData();
        }
    }, [])

    const newsData = news?.length ? news.map((el, i) => {
        const article = el?.article
        let formatDate = moment(el?.publishFrom).format("MMMM DD, YYYY");
        let x = article?.description && JSON.parse(article?.description);
        let editor;
        if (x) {
            editor = { children: [...x] }
        }

        const description = article?.description && article.description?.includes('"type":') ? serialize(editor) : article?.description;

        return <div className="news_card">
            <img className="img_responsive" src={article?.imageMetaData?.imageUrl ?? ""} />
            <div className="news_d">
                <div className="post_date"><i className="far fa-calendar"></i> {formatDate} </div>
                <h3 style={{ display: '-webkit-box', webkitLineClamp: '2', webkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {article?.title}
                </h3>
                <p style={{ display: '-webkit-box', webkitLineClamp: '3', webkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    dangerouslySetInnerHTML={{ __html: description }}>
                </p>

                <Link className="read_more" to={{
                    pathname: `/news-details/${article?.title.toLowerCase().replace(/ /g, "-")}`,
                    state: { 
                        news: article,
                        showFeaturePages: showFeaturePages
                    }
                }}>
                    READ MORE <ArrowRightAltIcon />
                </Link>
            </div>
            <div className="clearfix"></div>
        </div>

    }) : null;

    const getVideoId = (videoId) => {
        if (videoId) {
            setVideoId(videoId)
            setShowVideo(true)
        }

    }

    const newsListProps = {
        facebook : {facebookUrl},
        youtube: {youtubeUrl}
    }
    return (
        <div>

            <div className="fixed_bg"></div>
            <               div className="wrapper body">
                <Helmet>
                    <title>Darul Jannah - Reads/Views | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="READS/VIEWS" b_link="READS/VIEWS" />

                {/*-------------- news AND RECENT NEWS ------------*/}
                <div className="section">
                    <div className="container">
                        <div className="news_content">
                            <div className="row">


                                <div className="col-lg-8 col-md-12">
                                    <div className="sec_title_group">
                                        <h1 className="section_title">READS/VIEWS</h1>
                                    </div>

                                    {newsData}

                                </div>


                                <div className="col-lg-4 col-md-12">
                                {showFeaturePages?.videos?.enablePage &&
                                    <>
                                        <div className="sec_title_group">
                                            <h2 className="section_title">RECENT VIDEOS</h2>
                                        </div>
                                        <LatestVideosSidebar getVideoId={getVideoId} />
                                        <VideoPlayerModal
                                            isOpen={showVideo}
                                            onClose={() => setShowVideo(false)}
                                            vidoeID={videoId}
                                        />
                                    </>
                                }

                                    <div className="sidebar_section">
                                        <div className="sidebar_title">
                                            <h2>GET CONNECTED</h2>
                                        </div>
                                        <Socialicons orgSocials = {newsListProps}/>
                                    </div>


                                    {/*--------------- AD ------------*/}
                                    <Ad336X280 />

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                {/* <div className="section">
                <Ad728X90/>
            </div> */}

                {/*----------------------- WEEKLY PROGRAM SECTION ------------------ */}
                <HomePageWeeklyProgSection />
                {/*----------------------- Services List ----------------------------*/}
                {showFeaturePages?.services?.enablePage &&
                    <ServicesList />
                }

                {/* <div className="section">
                <Ad728X90/>
            </div> */}


                <div className="footer_space"></div>


            </div>
        </div>
    );
}

export default NewsList;