import React from "react";

function FooterAboutUs(props) {

    console.log("props: ", props)
    return (
        <div className="col-md-4 ftr_blog">
            <h1 className="ftr_blog_title">ABOUT US</h1>
            <p>Darul Jannah Masjid & Islamic Center, at 6 Avenue C, Brooklyn, NY 11218 is a fully religious and not-for-profit organization, which is serving the community in Kensington and Borough Park.</p>
            <p className="ftr_abt_secand_text">Darul Jannah is a 501(c)(3) Not-for-profit Organization.  Tax/EIN Number 11-3416965</p>
            <div className="ftr_top_social">
            { props.aboutUsProps.facebook &&  <a href={props.aboutUsProps.facebook} target="_blank"><i className="fab fa-facebook-f"></i></a> }
            { props.aboutUsProps.youtube &&  <a href={props.aboutUsProps.youtube} target="_blank"><i class="fab fa-youtube"></i></a> }
            { props.aboutUsProps.twitter &&  <a href={props.aboutUsProps.twitter} target="_blank"><i className="fab fa-twitter"></i></a> }
            
            </div>
        </div>
    );
}

export default FooterAboutUs;


