import React from 'react';
import HorizontalAdd from '../../Assets/imgs/HorizontalAdd.png'

function Ad728X90(props) {
    var baseUrl = '';
    if(props.base_url){
        baseUrl = props.base_url;
    }
    return (
            <div className="section addHorizontal">
                <a href={localStorage.getItem("donationUrl") ?? '#'} target="blank">
                    <img src={HorizontalAdd}  className="addHorizontalImg"/>
                </a>
            </div>
      
    );
}

export default Ad728X90;