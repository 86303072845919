import React, { useState, useEffect } from 'react';
import { getSalahs, getOrganization } from '../Home/apis';
import moment from 'moment';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function IqamahTime() {
    const [wakt, setwakt] = useState([{salahName: {}}])
    const [tz, setTZ] = useState('America/New_York')
    const [lastUpdated, setlastUpdated] = useState()

    const getTimeStamp = (time) => {
        let timestamp = moment(time).format("x");
        return timestamp
    }
    
    useEffect(() => {
   
        const getData = async () => {
            try {
                const resSalahGet = await getSalahs(orgId, 'deenweb')

                const SalahData = resSalahGet?.data?.body?.data

                const ActiveSalah = SalahData.filter(text => text.status === 'true')


                const resOrg = await getOrganization(orgId);
                const orgIntegate = resOrg.data?.body?.data?.enabledIntegrations;
                
                // console.log(orgIntegate)

                const salah = orgIntegate?.deenweb?.salahs?.selectedSalahs
                
                const d1 = new Date();
                const d = Number(d1)
                const resData = salah.filter((item => item.status === "Public"));  //&& Number(new Date(item.publishFrom)) < d && Number(new Date(item.publishTo)) > d)
                console.log("salah with App ID: ", resData)
                    
                const Ids = resData.length ? resData.map(t => t.salah) : [];
                // console.log("Active Salah")
                // console.log(Ids)
                
                let updatedSalahObj = []

                const SalahToDisplay = ActiveSalah.filter(text => Ids.includes(text._id))
                
                //console.log("SalahToDisplay: ", SalahToDisplay)

                let sortedSalah = SalahToDisplay?.filter((el) => el?.order).sort((a, b) => {
                    return a.order - b.order
            })

                const lu = sortedSalah?.length ? sortedSalah.sort((a, b) => {

                    return getTimeStamp(a.updatedAt) - getTimeStamp(b.updatedAt)
                }) : []

                console.log("lu: ", lu)
                // const resAnn = await getSalahs(orgId, "deenweb")
                
                // let resData = resAnn?.data?.body?.data ?? [];
                
                // const {salahEnd} = resData[0]
                // let dateTest = new Date(salahEnd);
                // console.log("salahEnd", salahEnd)

                // resData = resData.filter(({status, salahEnd, salahStart}) => status === "true" ) //&& new Date(salahStart.wakTime) <= new Date() && new Date(salahEnd) >= new Date()
                // console.log("resData filtered")
                // console.log(resData)
                
                
                // const lu = resData?.length ? resData.sort((a, b) => {

                //     return getTimeStamp(a.updatedAt) - getTimeStamp(b.updatedAt)
                // }) : []
                
                
                // console.log("getSalahs", resData)
                

                // const orderedData = resData.filter((el) => el?._id).sort((a, b) => {
                //     return a.order - b.order
                // })

                // console.log("orderedData: ")
                // console.log(sortedSalah)

                setwakt(sortedSalah)
                //setwakt(prevWakt => ([...prevWakt, ...orderedData]));
                setlastUpdated(lu?.[0]?.updatedAt)

                console.log("Last Updated: ", lu?.[0]?.updatedAt)
                } catch (err) {
                console.log(err)
                }
            };
           
            getData();
    }, [])

    useEffect(() => {
        console.log("WAKT: ")
        console.log(wakt)
       
    }, [wakt])

    return(
            
        <div className="slah_container">
        <div className="sec_title_group">
            <h1 className="section_title">IQAMAH TIME</h1>
        </div>
        <div className="iqama_time">
            <div className="table-responsive">
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th className="td_border">SALAH NAME</th>
                        <th>IQAMAH TIME</th>
                    </tr>
                    {
                    //    wakt && wakt.forEach(salah => {
                    //     let name = salah?.salahName?.fullName?.split("(")

                    
                    //     name = name?.length ? name[0] : salah?.salahName;
                    //     console.log(name)
                    //     console.log(salah?.salahStart?.iqamahTime)
                    //     console.log(salah.order)
                    //     let iqamahTime = moment(salah?.salahStart?.iqamahTime).format('hh:mm A')
                    //     console.log(iqamahTime)
                    //     return <tr key={salah.order}>
                    //         <td className="text-left td_border">{name ?? ""}</td>
                    //         <td className="text-right">{iqamahTime ? iqamahTime : ""}</td>
                    //     </tr>
                    //    })

                        wakt?.length ? wakt.map((salah) => {
                            let name = salah?.salahName?.fullName?.split("(")
                            
                            name = name?.length ? name[0] : salah?.salahName.fullName;
                            return <tr key={salah.order}>
                                <td className="text-left td_border">{name ?? ""}</td>
                                <td className="text-right">{salah?.salahStart?.iqamahTime ? moment(salah?.salahStart?.iqamahTime).utcOffset(0).format('LT') : ""}</td>
                            </tr>
                        }) : null
                    }
                    </tbody>
                </table>
                <div className="table_update_text">
                    <p>Iqamah Times are in New York / EST</p>
                    {/* <p>Last Updated DEC 11, 2019 / 12:43 AM</p> */}
                    {/* <p>Iqamah Times are in {wakt?.settings?.salahMethod?.timezone ? wakt?.settings?.salahMethod?.timezone : "New York / EST"}</p> */}
                    {lastUpdated && <p>Last Updated {lastUpdated ? moment(lastUpdated).format('LL') : ""} / {lastUpdated ? moment(lastUpdated).utcOffset(-5).format("h:mm A") + " EST" : ""}</p>}
                </div>
            </div>
        </div>
            </div>

        );

}

export default IqamahTime;
