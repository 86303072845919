import React, {useState, useEffect} from 'react';
import FooterAbout from '../FooterAboutUs/FooterAboutUs';
import FooterRecentVideos from '../FooterRecentVideos/FooterRecentVideos';
import IqamahTime from '../IqamahTime/IqamahTime';
import FooterNewsletterSubs from '../FooterSubscribeNewsletter/FooterSubscribeNewsletter';
import {Link} from "react-router-dom";
import moment from 'moment'

import MapIcon from './map_icon.png';
import DcLogo from './DC_Logo.png';

import { getOrganization } from "../Home/apis";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function FooterCommon(props) {
    const [organization, setorganization] = useState({})
    const [privacyPolicy, setPrivacyPolicy] = useState(true)
    const [termandconditions, setTermandconditions] = useState(true)
    const [showVideoSection, setShowVideoSection] = useState(true)
    const [facebookUrl, setFacebookurl] = useState('');
    const [youtubeUrl, seYoutubeUrl] = useState('');
    const [twitterUrl, seTwitterUrl] = useState('');
    const [orgSocials, setOrgSocials] = useState('');
    useEffect(() => {
        const getData = async () => {
            try {
                const resAnn = await getOrganization(orgId)
                const resData = resAnn?.data?.body?.data ?? {};
                
                setShowVideoSection(resData?.enabledIntegrations?.deenweb?.pages?.videos?.enablePage);
                const privacyPolicy = resData?.enabledIntegrations?.deenweb?.pages?.privacyPolicy?.enablePage ;
                const termandconditions = resData?.enabledIntegrations?.deenweb?.pages?.termsOfServices?.enablePage ;
                setPrivacyPolicy(privacyPolicy);
                setTermandconditions(termandconditions);
                console.log(resData, resAnn, "org")
                setorganization(resData);
                setFacebookurl(resData.orgSocials.facebook);
                seYoutubeUrl(resData.orgSocials.youtube)
                seTwitterUrl(resData.orgSocials.twitter)
                setOrgSocials(resData.orgSocials)

              } catch (err) {   
                console.log(err)
              }
        }
        getData();
    }, [])

    const openGoogleMap = () => {
    const orgAdrress = organization?.orgAddress ? (organization.orgAddress?.line1 ?? "") + " " + (organization.orgAddress.city ?? "") + " " + (organization.orgAddress?.state ?? "") + " " + (organization.orgAddress?.postalCode ?? "") : "";

        window.open(`https://www.google.com/maps/dir//${orgAdrress}`);

    }

    const aboutUsProps = {
        facebook : {facebookUrl},
        youtube: {youtubeUrl},
        twitter: {twitterUrl}
    }
    const webContactDetails = organization?.enabledIntegrations?.deenweb?.settings?.contactDetails ?? {};
    console.log("webContactDetails.secondaryPhone: ", webContactDetails.secondaryPhone)
    // console.log(organization.orgSocials.youtube);
        return (    
            <div>
                <div className="footer">
                    <div className="container">
                        <div className="row">

                            {/*----------- ABOUT US ----------*/}
                            <FooterAbout  aboutUsProps={orgSocials}/>
                            {/* <FooterAbout facebookUrl = {organization.orgSocials.facebook}/> */}
                            {/*----------- Recent Videos -----*/}
                            {showVideoSection && 
                                <FooterRecentVideos getFooterVideoId = {props.getFooterVideoId}/>
                            }

                            <div className="col-md-4 ftr_blog">
                                <IqamahTime />
                            </div>

                        </div>
                    </div>

                    <div className="footer_2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 ftr_blog">
                                    <h1 className="ftr_blog_title">CONTACT INFO</h1>
                                    <p><i className="fas fa-map-marked-alt"></i>
                                        <span>{organization?.orgAddress?.line1 ?? ""} <br /> {organization?.orgAddress?.city ?? ""}, {organization?.orgAddress?.state ?? ""} {organization?.orgAddress?.postalCode ?? ""}</span></p>
                                    <p><i className="fas fa-phone-alt"></i>
                                        <span>{webContactDetails?.primaryPhone ?? ""} <br /> {webContactDetails?.secondaryPhone === "+" ? "" : webContactDetails?.secondaryPhone}</span>
                                    </p>
                                    <p><i className="fas fa-envelope"></i> <span>{webContactDetails?.email ?? ""}</span></p>
                                    <div className="ftr_btn"><Link className="ftr_btn" to={{ pathname: "/about-us", state: { section: "connect-form" } }}>CONTACT US</Link></div>
                                </div>
                                <div className="col-md-4 ftr_blog">
                                    <h1 className="ftr_blog_title">GIVE US A VISIT</h1>
                                    <p>Give us a visit and earn the Hasanah of praying together in jam’at!</p>

                                    <div className="loc_icon">
                                        <img src={MapIcon} alt="Map" />
                                    </div>

                                    <div id="#GetdirectionBtn" className="ftr_btn"><Link className="ftr_btn" onClick = {openGoogleMap} >GET DIRECTION</Link></div>
                                </div>

                                {/*------------- FOOTER NEWSLETTER SUBSCRIBE -------------*/}
                                <FooterNewsletterSubs showAlert={props.showAlert} />


                            </div>
                        </div>
                    </div>

                </div>

                {/*----------------- FOOTER BOTTOM 2 SECTIONS ---------------------*/}
                <div className="ftr_btm_grey">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-6 col-md-4 col-sm-6">
                                <div className="tx_box term_links">
                                    {termandconditions && 
                                        <Link className="" to="/TermsPolicies"> TERMS OF SERVICE </Link>
                                    }
                                    {termandconditions && privacyPolicy && 
                                        <span>|</span>
                                    }
                                    {privacyPolicy && 
                                        <Link className="active" to="/PrivacyPolicy"> Privacy Policy </Link>
                                    }
                                    
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-4 col-sm-6">
                                <div className="tx_box text-right">Crafted With<i className="fa fa-heart" aria-hidden="true"></i>In Islam
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {/*------------------ Last Bottom Section ------------------*/}
                <div className="ftr_btm">
                    <div className="container">
                        <div className="row">
                            
                            <div className="col-lg-4 col-md-4 col-sm-4 ver_aling">
                                <div className="tx_box">
                                © {moment().format("YYYY")} deeconnect. ALL RIGHT RESERVED
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 ver_aling">
                                
                                <div className="tx_box">
                                    <div className="powered_by"> Powered by
                                        <a href="https://deenconnect.io/" target="_blank"><img className="dc_logo_ftr" src={DcLogo} alt="DC Logo" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 ver_aling right_aligh">
                                <div className="tx_box text-right">
                                    Visit us - <a href="https://deenconnect.io/" target="_blank">www.deenconnect.io</a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
}

export default FooterCommon;