import React from 'react';
import Helmet from "react-helmet";
import BreadCrumbSection from "../../BreadCrumbSection/BreadCrumbSection";

class DeathReportForm extends React.Component {
    render(){
        return (
            <div className="wrapper">

                <Helmet>
                   <title>Darul Jannah - Report a Death | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="REPORT A DEATH" b_link="REPORT A DEATH"/>
                {/*-------------- FORM STARTED ----------------------*/}
                <div className="page_content">
                    <p className="gv_content">
                        And the life of this world is nothing but play and amusement. But far better is the house in
                        the hereafter for those who are Al-Muttaqun (the pious). Will you not then understand?
                        <span>(Quran, Al-An’aam: 32)</span>
                    </p>
                </div>

            <div className="container gv_layout_setup gv_form_main_layout mb-lg-5">

                <div className="form_title">
                    <h1>ONLY PRAY SALATUL JANAZA</h1>
                    <p>Please Fill in the Form Below</p>
                    <div className="d_report_heading2">Your/Reporter Details</div>
                </div>

                <form className="gv_form row">

                    <div className="row gv_form_layout">

                        <div className="col-md-4 col-lg-4">
                            <div className="form-group input-group">
                                <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="label-name" placeholder="Enter your first name" required />
                                  <label htmlFor="label-name" >First Name</label>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4">
                            <div className="form-group input-group">
                                <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="label-name1" placeholder="Enter your middle name" required />
                                  <label htmlFor="label-name1">Middle Name</label>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4">
                            <div className="form-group input-group">
                                <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="label-name2" placeholder="Enter your last name" required />
                                  <label htmlFor="label-name2">Last Name</label>
                                </span>
                            </div>
                        </div>



                    <div className="row gv_form_layout">

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group input-group">
                              <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="dob" placeholder="Enter your date of birth  " required />
                                <label htmlFor="dob">Date of Birth</label>
                                <div className="fld_icon"><i className="far fa-calendar"></i></div>
                              </span>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-6">
                            <div className="form-group input-group">
                                <span className="border-lable-flt">
                                    <input type="text" className="form-control" id="mobile" placeholder="Enter your mobile phone number" required />
                                  <label htmlFor="mobile">Mobile Phone</label>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div className="row gv_form_layout">
                        <div className="col-md-12 col-lg-12">
                            <div className="form-group input-group bmd-form-group">
                                <label className="form-group border-lable-flt">
                                    <select name="relationship_to_dec" className="form-control custom-select">
                                        <option selected="">Relationship to Deceased</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>


                    <div  className="gv_form_sec_title">Deceased Details</div>

                    <div className="row gv_form_layout">

                        <div className="col-md-4 col-lg-4">
                            <div className="form-group input-group">
                                <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="d-label-name" placeholder="Enter deceased first name" required />
                                  <label htmlFor="d-label-name">First Name</label>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="form-group input-group">
                                <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="d-label-name1" placeholder="Enter deceased middle name" required />
                                  <label htmlFor="d-label-name1">Middle Name</label>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="form-group input-group">
                                <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="d-label-name2" placeholder="Enter deceased last name" required />
                                  <label htmlFor="d-label-name2">Last Name</label>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div className="row gv_form_layout">

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group input-group">
                              <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="d_age" placeholder="deceased age" required />
                                <label htmlFor="d_dod">Age</label>
                              </span>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-6">
                            <div className="form-group input-group bmd-form-group">
                                <label className="form-group border-lable-flt">
                                    <select name="d_gender" className="form-control custom-select" defaultValue={''}>
                                        <option value=''>Gender</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div className="row gv_form_layout">

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group input-group">
                              <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="d_dod" placeholder="Enter date of death" required />
                                <label htmlFor="d_dod">Date of Death</label>
                                <div className="fld_icon"><i className="far fa-calendar"></i></div>
                              </span>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-6">
                            <div className="form-group input-group">
                              <span className="border-lable-flt">
                                  <input type="text" className="form-control" id="t_dod" placeholder="Enter time of death" required />
                                <label htmlFor="t_dod">Time of Death</label>
                                <div className="fld_icon"><i className="far fa-clock"></i></div>
                              </span>
                            </div>
                        </div>

                    </div>



                    <div className="row gv_form_layout">

                        <div className="col-md-12 col-lg-12 mb-lg-5">
                            <div className="form-group input-group">
                                <span className="form_txt_area">
                                  <textarea name="reason_of_death" id="r_o_death" cols="30" rows="4" placeholder="Please provide details of the place and reason of death." className="form-control"></textarea>
                                  <small className="float-right">CHAR 45/100</small>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div className="row gv_form_layout">

                        <div className="col-md-4 col-lg-4">
                            <div className="form-group input-group bmd-form-group">
                                <label className="form-group border-lable-flt">
                                    <select name="country_o_b" className="form-control custom-select">
                                        <option selected="">Country of Birth</option>
                                    </select>
                                </label>

                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4">
                            <div className="form-group input-group bmd-form-group">
                                <label className="form-group border-lable-flt">
                                    <select name="city_town" className="form-control custom-select">
                                        <option selected="">City/Town</option>
                                    </select>
                                </label>

                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4">
                            <div className="form-group input-group bmd-form-group">
                                <label className="form-group border-lable-flt">
                                    <select name="state_p" className="form-control custom-select">
                                        <option selected="">State/Province</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div  className="gv_form_sec_title">Salatul Janazah - Date & Time</div>

                    <div className="row gv_form_layout">

                        <div className="col-md-6 col-lg-6">
                            <div className="form-group input-group">
                                <span className="border-lable-flt">
                                    <input type="text" className="form-control" id="j_date" placeholder="Date of Salatul Janazah" required />
                                  <label htmlFor="j_date">Select Date</label>
                                  <div className="fld_icon"><i className="far fa-calendar"></i></div>
                                </span>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-6">
                            <div className="form-group input-group">
                                <span className="border-lable-flt">
                                    <input type="text" className="form-control" id="j_time" placeholder="Enter time of Salatul Janazah" required />
                                  <label htmlFor="j_time">Time of Salatul Janazah</label>
                                  <div className="fld_icon"><i className="far fa-clock"></i></div>
                                </span>
                            </div>
                        </div>

                    </div>


                    <div className="form_btn"><button className="btn_submit">SUBMIT FORM</button></div>

                  </div>
                </form>
            </div>

            <div className="footer_space"></div>


            </div>
        );
    }
}

export default DeathReportForm;