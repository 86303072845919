import React, { useState, useEffect } from 'react';
import HomeSlider from '../HomeSlider/HomeSlider';
import LatestNews from '../LatestNews/LatestNews';
import AboutUsAayatSlider from '../AboutUsAayatSlider/AboutUsAayatSlider';
import HomePageVideosSection from '../HomePageVideosSection/HomePageVideosSection';
import UpcomingEvents from '../UpcomingEvents/UpcomingEvents';
import HomePageWeeklyProgSection from '../HomePageWeeklyProgSection/HomePageWeeklyProgSection';
import ServicesList from '../ServicesList/ServicesList';
import ReserveYourToday from '../ReserveYourToday/ReserveYourToday';
import HomeSalatJanazaRequest from '../HomeSalatJanazaReq/HomeSalatJanazaReq';
import Helmet from "react-helmet";
import { getEvents, getNews, getOrganization } from "./apis";
import { VideoPlayerModal } from "master-react-lib";
import Ad336X280 from '../Ad336X280/Ad336X280';


const orgId = process.env.REACT_APP_ORGANIZATION_ID;

export default function Home() {
    const [events, setevents] = useState([])
    const [news, setnews] = useState([])
    const [videoId, setVideoId] = useState(null)
    const [showVideo, setShowVideo] = useState(false);
    const [showPages, setShowPages] = useState({});

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            try {
                const orgData = await getOrganization(orgId);
                if(orgData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages) {
                    setShowPages(orgData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages);
                } 
                let resEvents = await getEvents(orgId, "deenweb")
                resEvents = resEvents?.data?.body?.data ?? [];
                resEvents = resEvents.filter(({status, publishTo, publishFrom}) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
                resEvents = resEvents?.length ? resEvents.filter((el) => el.featured === true) : []
                if(resEvents.length > 0) {
                    resEvents = resEvents.sort((a, b) => a?.event?.order - b?.event?.order)
                }
                console.log("resEvents", resEvents)
                setevents(resEvents);
            

            } catch (err) {
                console.log("here", err)
            }

            try {
                let resNews = await getNews(orgId, "deenweb")
                resNews = resNews?.data?.body?.data ?? [];
                resNews = resNews.filter(({status, publishTo, publishFrom}) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
                resNews = resNews?.length ? resNews.filter((el) => el.featured === true) : [];
                if(resNews.length > 0) {
                    resNews = resNews.sort((a, b) => a?.article?.order - b?.article?.order)
                }
                setnews(resNews)

            } catch (err) {
                console.log("here", err)
            }
        }
        getData();
    }, [])

    const getVideoId = (videoId) => {
        if (videoId) {
            setVideoId(videoId)
            setShowVideo(true)
        }

    }

    return (
        <div>
            <div className="fixed_bg"></div>
            <               div className="body">
                {/*------- Update website title -------*/}
                <Helmet>
                    <title>Darul Jannah - Home | Powered by DEENCONNECT</title>
                </Helmet>
                {/*<HeaderNotificationSlider />
            <NavigationBar/>*/}

                <div className="wrapper">

                    {/*------ SLIDER ------*/}
                    <HomeSlider />
                    {showPages?.articles?.enablePage && news.length > 0 &&
                        <LatestNews news={news} />
                    }
                    {/*------------------- AAYAT SECTION START ----------------*/}
                    <div className="section">
                        <div className="container">
                            <div className="row">
                                {/*------------------- AAYAT SLIDER ------------------------*/}
                                <AboutUsAayatSlider />

                                <div className="col-md-4">
                                     <Ad336X280 /> 
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*-------- VIDEOS SECTION ------*/}
                    {showPages?.videos?.enablePage &&
                        <>
                            <HomePageVideosSection getVideoId = {getVideoId}/>
                            <VideoPlayerModal
                                isOpen={showVideo}
                                onClose={() => setShowVideo(false)}
                                vidoeID={videoId}
                            />
                        </>
                    }
                    {/*--------- Upcoming Events -----*/}
                    {showPages?.events?.enablePage && (events.length > 0) &&
                        <UpcomingEvents events={events} news = {news}/>
                    }
                    {/*------- Weekly Programs --------*/}
                    <HomePageWeeklyProgSection />
                    {/*------- Services List ----------*/}
                    {showPages?.services?.enablePage && 
                        <ServicesList />
                    }

                    {/*------- Reserve Your Today ------*/}
                    {/* <ReserveYourToday />
                     <HomeSalatJanazaRequest /> */}
                    {/*<FooterCommon />*/}

                </div>
                {/*-------- FOOTER START -----------*/}


            </div>

        </div>
    );
}