import React from 'react';
import IqamahTime from '../IqamahTime/IqamahTime';
import { Link } from "react-router-dom";
import moment from "moment";
import { serialize } from "../../utils/markdowntoreact";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

function App({ news }) {
    const newsData = news?.length ? news.slice(1, 4).map((el, i) => {
        const article = el?.article
        let formatDate = moment(el?.publishFrom).format("MMMM DD, YYYY");

        return <li className="nav-item" key={i}>
            <Link className="nav-link" to={{
                pathname: "/news-details/static-news-detail",
                state: { news: article }
            }}>
                {/* <a className="nav-link" href="#schedule-1" role="tab" data-toggle="tab"> */}
                <div className="v_list">
                    {/* <div className="v_list_thumb">
                        <img className="vd_tumb" src={article?.imageMetaData?.imageUrl ?? ""} alt="videos" />
                    </div> */}
                    <div className="v_details">
                        <div className="post_date"><i className="far fa-calendar"></i> {formatDate} </div>
                        <h5>{article ? article.title : ''}</h5>
                        <Link className="read_more" to={{
                            pathname: "/news-details/static-news-detail",
                            state: { news: news[0]?.article }
                        }}>READ THE FULL STORY <ArrowRightAltIcon /></Link>
                    </div>
                    <div className="clearfix"></div>
                </div>
                {/* </a> */}
            </Link>
        </li>

    }) : null;

    let x = news?.[0]?.article?.description && JSON.parse(news[0].article?.description);
    let editor;
    if (x) {
        editor = { children: [...x] }
    }

    const description = news?.[0]?.article?.description && news?.[0]?.article?.description?.includes('"type":') ? serialize(editor) : news?.[0]?.article?.description;


    return (
        <div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            {news?.length ?
                                <><div className="sec_title_group">
                                    <h1 className="section_title">READS/VIEWS</h1>
                                </div>
                                    <div className="news_content">

                                        {news?.length ? <div className="tab-content">
                                            <div className="" id="dashboard-1">
                                                <div className="news_img">
                                                    <Link style={{ textDecoration: 'none' }} to={{
                                                        pathname: "/news-details/static-news-detail",
                                                        state: { news: news[0]?.article }
                                                    }}>
                                                        <img className="img_responsive largeImg" src={news[0]?.article?.imageMetaData?.imageUrl ?? ""} />
                                                    </Link>
                                                </div>
                                                <div className="post_date"><i className="far fa-calendar"></i>
                                                    {moment(news[0]?.publishFrom).format("MMMM DD, YYYY")}
                                                </div>
                                                <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={{
                                                    pathname: "/news-details/static-news-detail",
                                                    state: { news: news[0]?.article }
                                                }}>
                                                    <h2 className="post_sm_title">{news?.[0]?.article?.title}</h2>
                                                </Link>
                                                {/* <p dangerouslySetInnerHTML={{ __html: description.substring(0, 200) }}></p> */}
                                                <Link className="read_more" to={{
                                                    pathname: "/news-details/static-news-detail",
                                                    state: { news: news[0]?.article }
                                                }}>READ THE FULL STORY <ArrowRightAltIcon /></Link>
                                            </div>
                                        </div> : null}


                                        <ul className="nav nav-pills nav-pills-icons news_listing" role="tablist">
                                            {newsData}
                                        </ul>
                                        <div className="clearfix"></div>
                                        <div className="more_view">
                                            <Link to="news-list" to={{
                                                pathname: "/news-list",
                                                state: { news: news }
                                            }}>View All Reads/Views</Link>
                                        </div>
                                    </div>
                                </> : null}
                        </div>
                        <div className="col-lg-4 col-md-12 iqama_colm">
                            <IqamahTime />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
