import React from 'react';

function EidGallery() {
    return (
        <div className="section">
            <div className="dark_section">
                <div className="container">

                    <div className="sec_title_group">
                        <h1 className="section_title">EID GALLERY</h1>
                    </div>

                    <div className="gallery eid_lightbox">
                        <a href="assets/img/gallery/1.jpg" data-lightbox="photos"><img className="img-fluid"
                                                                                       src="assets/img/gallery/1.png" /></a>
                        <a href="assets/img/gallery/2.png" data-lightbox="photos"><img className="img-fluid"
                                                                                       src="assets/img/gallery/2.png" /></a>
                        <a href="assets/img/gallery/3.png" data-lightbox="photos"><img className="img-fluid"
                                                                                       src="assets/img/gallery/3.png" /></a>
                        <a href="assets/img/gallery/4.png" data-lightbox="photos"><img className="img-fluid"
                                                                                       src="assets/img/gallery/4.png" /></a>
                        <a href="assets/img/gallery/5.png" data-lightbox="photos"><img className="img-fluid"
                                                                                       src="assets/img/gallery/5.png" /></a>
                        <div className="clearfix"></div>
                    </div>
                    <div className="more_view"><a href="#">SHOW MORE</a></div>

                </div>
            </div>
        </div>
    );
}

export default EidGallery;

