import React, {useState,useEffect} from 'react';
import { Link, withRouter } from 'react-router-dom';

import Logo from './logo.png';
import { getServices, getOrganization } from "../Home/apis";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;



function NavigationBar(props) {
        const [services, setservices] = useState([])
        const [path, setpath] = useState("/")
        const [actiPages, setActiPages] = useState({})
        const [deenfundUrl, setDeenfundUrl] = useState('')
        const [facebookUrl, setFacebookUrl] = useState('');
        const [youtubeUrl, setYoutubeUrl] = useState('');


        useEffect(() => {
            const getData = async () => {
                try {
                    let resService = await getServices(orgId, "deenweb");
                    let orgData = await getOrganization(orgId);
                    resService = resService?.data?.body?.data ?? [];
                    const activePages = orgData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages ?? {};
                    const donationUrl = orgData?.data?.body?.data?.enabledIntegrations?.deenfund?.embedForm?.[0]?.formLink ?? '';
                    if(donationUrl && !localStorage.getItem("donationUrl")) {
                        localStorage.setItem("donationUrl", donationUrl);
                    }
                    setDeenfundUrl(donationUrl);
                    setservices(resService)
                    setActiPages(activePages);
                    // console.log('res service ');
                    // console.log( resService );
                    setFacebookUrl(orgData.data.body.data.orgSocials.facebook);
                    setYoutubeUrl(orgData.data.body.data.orgSocials.youtube);

                    {console.log("facebookUrl: ", facebookUrl)}
                } catch (err) {
                    console.log(err)
                }
            }
            getData();
        }, [])

        const servicesData = services?.length ? services.map(({service}, i) => {
            return <Link key={i} to={{ pathname: "/about-us", state: { tab: i + 1 } }} className="dropdown-item">{service?.name}</Link> 
        }) : null;


    var baseUrl = '';
    if(props.base_url){
        baseUrl = props.base_url;
    }

    useEffect(() => {
        setpath(window.location.pathname);
    }, [window.location.pathname])

    return (
        <nav className="navbar navbar-transparent navbar-color-on-scroll fixed-top navbar-expand-lg" color-on-scroll="100" id="sectionsNav">

			 <div className="navs_container">
            <div className="container nav_bar_container">
                <div className="navbar-translate">

				   <Link className="navbar-brand" to='/'>
                        <img src={Logo} alt="Logo" />
					 </Link>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto header_social">
                        <li className="nav-item">
                            
                        {facebookUrl ? <a  className="nav-link" rel="tooltip" title=""  data-placement="bottom" href={facebookUrl} target="_blank" data-original-title="Follow us on Facebook">
                                <i  className="fab fa-facebook-f"></i>
                            </a>
                            : "" }
                        </li>
                        <li className="nav-item">
                            
                        {youtubeUrl ? <a  className="nav-link" rel="tooltip" title=""  data-placement="bottom" href={youtubeUrl} target="_blank" data-original-title="Follow us on Youtube">
                            <i class="fab fa-youtube"></i>
                            </a>
                            : "" }
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" rel="tooltip" title="" data-placement="bottom" href="https://twitter.com/" target="_blank" data-original-title="Follow us on Twitter ">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li> */}
                        {/* <li className="nav-item">
                            <a className="nav-link" rel="tooltip" title="" data-placement="bottom" href="#" target="_blank" data-original-title="Follow us on Youtube">
                            <i className="fab fa-youtube"></i>
                            </a>
                        </li> */}
                    </ul>
                    <a href={deenfundUrl} className="button donate_btn_header" target="blank">DONATE</a>
                    <div className="clearfix"></div>
                    <div className="navigation">
                        <ul className="menu_box_list">
                            {actiPages?.homePage?.enablePage && 
                                <li className={`menulinks ${path === "/" || path === "" ? "navactive" : ""}`}>
                                    <Link to="/">HOME</Link>
                                </li>
                            }
                            
                            {actiPages?.aboutUs?.enablePage && 
                                <li className={`menulinks ${path === "/about-us" ? "navactive" : ""}`}>
                                    <Link to="/about-us"> ABOUT US </Link>
                                </li>
                            }
							
                            {actiPages?.services?.enablePage && 
                                <>
                                    
                                    <li className="dropdown nav-item"> 
                                    <span>
                                        <Link to={{ pathname: "/about-us", state: { tab: 1 } }} style = {{paddingRight: '0'}} >
                                        SERVICES
                                        </Link>
                                    </span> 
                                        <a href="#pablo" className="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" style = {{paddingLeft : '0'}}>
                                        <div className="ripple-container"></div></a>

                                        <div className="dropdown-menu"> 
                                            {servicesData}
                                        </div>

                                    </li>
                                </>
                            }
							
                            {actiPages?.videos?.enablePage && 
                                <li className={`menulinks ${path === "/latest-videos" ? "navactive" : ""}`}>
                                    <Link to="/latest-videos"> VIDEOS </Link>
                                </li> 
                            }

                            {actiPages?.events?.enablePage && 
                                <li className={`menulinks ${(path === "/event-list" || path.includes("/event-details/")) ? "navactive" : ""}`}>
                                    <Link to="/event-list"> EVENTS</Link>
                                </li> 
                            }

                            {actiPages?.articles?.enablePage && 
                                <li className={`menulinks ${(path === "/news-list" || path.includes("/news-details/") ) ? "navactive" : ""}`}>
                                    <Link to="/news-list"> READS/VIEWS </Link>
                                </li> 
                            }

                            {actiPages?.faqs?.enablePage && 
                                <li className={`menulinks ${path === "/FAQ" ? "navactive" : ""}`}>
                                    <Link to="/FAQ"> FAQ </Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
			</div>
        </nav>
    );
}




export default withRouter(NavigationBar);
