import React from 'react';
import Helmet from "react-helmet";
import BreadCrumbSection from "../../BreadCrumbSection/BreadCrumbSection";

class IftarSponsorForm extends React.Component {
    render(){
        return (
            <div className="wrapper">

                <Helmet>
                    <title>Darul Jannah - Iftar Sponsor | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="IFTAR SPONSOR FORM" b_link="IFTAR SPONSOR"/>

                <div className="page_content">
                    <p className="gv_content">
                        And the life of this world is nothing but play and amusement. But far better is the house in
                        the hereafter for those who are Al-Muttaqun (the pious). Will you not then understand?
                        <span>(Quran, Al-An’aam: 32)</span></p>
                </div>


                 {/*------------- FORM STARTED -----------------------*/}

                <div className="container gv_layout_setup gv_form_main_layout mb-lg-5">

                    <div className="form_title">
                        <h1>SPONSOR AN IFTAR</h1>
                        <p>Please fill in the information below to sponsor Iftar</p>
                    </div>


                    <div className="form_tab_container">
                        <ul className="nav nav-pills nav-pills-icons form_tabs" role="tablist">

                            <li className="nav-item">
                                <a className="nav-link active" href="#fully_sponsor" role="tab" data-toggle="tab">
                                    <i className="fa fa-check-circle payment_check_icon"></i>
                                    <p className="payment_tabs_setting"><i className="fas fa-hand-holding-usd"></i></p>
                                    I want to fully sponsor
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="#co_sponsor" role="tab" data-toggle="tab">
                                    <i className="fa fa-check-circle payment_check_icon"></i>
                                    <p className="payment_tabs_setting"><i className="fa fa-handshake"></i></p>
                                    I want to co-sponsor
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/*-------------------- FULLY SPONSOR --------------------*/}

                    <div className="tab-content tab-space">
                        <div className="tab-pane active" id="fully_sponsor">


                            <div className="gv_form_sec_title">Sponsor Details</div>

                            <form className="gv_form row">

                                <div className="row gv_form_layout">

                                    <div className="col-md-4 col-lg-4">
                                        <div className="form-group input-group">
                                            <span className="border-lable-flt">
                                              <input type="text" className="form-control" id="label-name" placeholder="Enter your first name" required />
                                              <label htmlFor="label-name">First Name</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4">
                                        <div className="form-group input-group">
                                            <span className="border-lable-flt">
                                              <input type="text" className="form-control" id="label-name1" placeholder="Enter your middle name" required />
                                              <label htmlFor="label-name1">Middle Name</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4">
                                        <div className="form-group input-group">
                                            <span className="border-lable-flt">
                                              <input type="text" className="form-control" id="label-name2" placeholder="Enter your last name" required />
                                              <label htmlFor="label-name2">Last Name</label>
                                            </span>
                                        </div>
                                    </div>

                                </div>

                                <div className="row gv_form_layout">
                                    <div className="col-md-6 col-lg-6">
                                        <div className="form-group input-group">
                                            <span className="border-lable-flt">
                                                <input type="text" className="form-control" id="phone_no" placeholder="Enter your phone number" required />
                                              <label htmlFor="phone_no">Phone No.</label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-6">
                                        <div className="form-group input-group">
                                            <span className="border-lable-flt">
                                              <input type="email" className="form-control" id="email" placeholder="Enter your email address" required />
                                              <label htmlFor="email">Email</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row gv_form_layout">
                                    <div className="col-md-8 col-lg-8">
                                        <div className="form-group input-group">
                                            <span className="border-lable-flt">
                                                <input type="text" className="form-control" id="street" placeholder="Enter your Street number address" required />
                                              <label htmlFor="street">Street Address</label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-lg-4">
                                        <div className="form-group input-group">
                                            <span className="border-lable-flt">
                                                <input type="text" className="form-control" id="apt_floor" placeholder="Enter your Apartment/Floor No" required />
                                              <label htmlFor="apt_floor">Apt/Floor</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row gv_form_layout">

                                    <div className="col-md-4 col-lg-4">
                                        <div className="form-group input-group bmd-form-group">
                                            <label className="form-group border-lable-flt">
                                                <select name="city" className="form-control custom-select">
                                                    <option selected="">City</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-lg-4">
                                        <div className="form-group input-group bmd-form-group">
                                            <label className="form-group border-lable-flt">
                                                <select name="state" className="form-control custom-select">
                                                    <option selected="">State</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4">
                                        <div className="form-group input-group">
                                            <span className="border-lable-flt">
                                              <input type="text" className="form-control" id="zipcode" placeholder="Enter your Zip Code" required />
                                              <label htmlFor="zipcode">Zip Code</label>
                                            </span>
                                        </div>
                                    </div>

                                </div>

                                <hr className="gv_form_line_style" />

                                    <div className="row gv_form_layout">

                                        <div className="col-md-12 col-lg-12">

                                            <p>
                                                Darul Jannah Masjid & Islamic Center (Darul Jannah) is faith based
                                                Muslim organization providing a broad
                                                platform for practicing and presenting Islam, supporting the development
                                                of Muslim communities and
                                                fostering dialogue, education, and civic engagement.
                                            </p>
                                            <p>
                                                Any Muslim adult living in North America who endeavors to practicing
                                                Islam as a total way of life may
                                                become a member of Darul Jannah
                                            </p>
                                            <br/>
                                        </div>
                                    </div>

                                    <div className="row gv_form_layout">

                                        <div className="col-md-12">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" />
                                                        I agree to work with Darul-Jannah Masjid & Islamic Center and I
                                                        also agree with its purpose to help the muslim community and
                                                        together we all seek Allah's help, so that we can practice islam
                                                        as a way of life.
                                                        <span className="form-check-sign">
                                                            <span className="check"></span>
                                                      </span>
                                                </label>
                                            </div>

                                            <br/>
                                            <br/>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" />
                                                        Mail me Membership Certificate at the above mailing address.
                                                        <span className="form-check-sign">
                                                            <span className="check"></span>
                                                      </span>
                                                </label>
                                            </div>

                                        </div>


                                        <div className="form_btn">
                                            <button className="btn_submit">SUBMIT FORM</button>
                                        </div>
                                    </div>


                            </form>


                        </div>
                        <div className="tab-pane" id="co_sponsor">

                        </div>

                    </div>

                </div>

                 {/*------------- FORM END HERE -----------------------*/}

                <div className="footer_space"></div>

            </div>
        );
    }
}

export default IftarSponsorForm;