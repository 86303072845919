import React, {useState, useEffect} from "react";
import { getVideos } from "../Home/apis";
import {Link} from "react-router-dom";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function FooterRecentVideos(props) {

        const [videos, setvideos] = useState([])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            try {
                const resAnn = await getVideos(orgId, "deenweb")
                let resData = resAnn.data.body.data;
                resData = resData.filter(({status, publishTo, publishFrom}) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
                setvideos(resData)
    
              } catch (err) {
                console.log(err)
              }
        }
        getData();
    }, [])


    const videosData = videos?.length ? videos.slice(0, 4).map(({video}, i) => {
        let videoId = video?.link?.split("/embed/")?.[1] ? video.link.split("/embed/")[1] : null;

        return <li key={i}><Link onClick = {() => props.getFooterVideoId(videoId)}><i className="vd_icon fas fa-video"></i> {video?.title ?? ""}</Link></li>
    }) : null;


    return (
        <div className="col-md-4 ftr_blog">
            <h1 className="ftr_blog_title">RECENT VIDEOS</h1>
            <ul>
                {videosData}
            </ul>
        </div>
    );
}

export default FooterRecentVideos;