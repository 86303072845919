import React from 'react';
import speekerImg from '../../Assets/imgs/speakers.png'


function SpeakerSlider({event}) {
        
    return (
        <div className="section">
            <div className="sec_title_group slider_arrow">
                <h1 className="section_title">SPEAKERS</h1>

                <div className="controls-top">
                    <a className="btn-floating prv" href="#multi-item-example" data-slide="prev"><i className="fa fa-chevron-left"></i></a>
                    <a className="btn-floating next" href="#multi-item-example" data-slide="next"><i className="fa fa-chevron-right"></i></a>
                </div>

                <div className="clearfix"></div>
            </div>

            <div id="multi-item-example" className="carousel slide carousel-multi-item" data-ride="carousel">
                <div className="carousel-inner" role="listbox">


                    <div className="carousel-item active">

                        <div className="row">

                            {event?.speakers?.length ? event.speakers.map((speaker) => { 
                                return <div className="speakers_col">
                                <a href="#" className="speaker_card">
                                    <img className="vd_tumb" src={speaker?.imageMetaData?.imageUrl ?? ""} alt="speakers" />
                                        <div className="speaker_card_body">
                                            <h4>{speaker?.name?.firstName + " " + (speaker?.name?.lastName ?? "")}</h4>
                                            <p>DARUL JANNAH KHATIB</p>
                                        </div>
                                </a>
                            </div>
                            }) : null}

                        </div>

                    </div>

                    {/* <div className="carousel-item">

                        <div className="row">

                            <div className="speakers_col">
                                <a href="#" className="speaker_card">
                                    <img className="vd_tumb img_responsive" src={speekerImg} alt="speakers" />
                                        <div className="speaker_card_body">
                                            <h4>IMAM SIRAJ WAHHAJ</h4>
                                            <p>DARUL JANNAH KHATIB</p>
                                        </div>
                                </a>
                            </div>

                            <div className="speakers_col">
                                <a href="#" className="speaker_card">
                                    <img className="vd_tumb img_responsive" src={speekerImg} alt="speakers" />
                                        <div className="speaker_card_body">
                                            <h4>IMAM SIRAJ WAHHAJ</h4>
                                            <p>DARUL JANNAH KHATIB</p>
                                        </div>
                                </a>
                            </div>

                            <div className="speakers_col">
                                <a href="#" className="speaker_card">
                                    <img className="vd_tumb img_responsive" src={speekerImg} alt="speakers" />
                                        <div className="speaker_card_body">
                                            <h4>IMAM SIRAJ WAHHAJ</h4>
                                            <p>DARUL JANNAH KHATIB</p>
                                        </div>
                                </a>
                            </div>

                        </div>

                    </div>

                    <div className="carousel-item"> */}

                        {/* <div className="row">

                            <div className="speakers_col">
                                <a href="#" className="speaker_card">
                                    <img className="vd_tumb" src={speekerImg} alt="speakers" />
                                        <div className="speaker_card_body">
                                            <h4>IMAM SIRAJ WAHHAJ</h4>
                                            <p>DARUL JANNAH KHATIB</p>
                                        </div>
                                </a>
                            </div>

                            <div className="speakers_col">
                                <a href="#" className="speaker_card">
                                    <img className="vd_tumb" src={speekerImg} alt="speakers" />
                                        <div className="speaker_card_body">
                                            <h4>IMAM SIRAJ WAHHAJ</h4>
                                            <p>DARUL JANNAH KHATIB</p>
                                        </div>
                                </a>
                            </div>

                            <div className="speakers_col">
                                <a href="#" className="speaker_card">
                                    <img className="vd_tumb" src={speekerImg} alt="speakers" />
                                        <div className="speaker_card_body">
                                            <h4>IMAM SIRAJ WAHHAJ</h4>
                                            <p>DARUL JANNAH KHATIB</p>
                                        </div>
                                </a>
                            </div>

                        </div>
                    </div> */}
                
                </div>
            </div>
        </div>
    );
}


export default SpeakerSlider;