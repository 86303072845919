import React from 'react';

class EidTimeCounter extends React.Component {
    render(){
        return (
            <div className="section">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="fs_date_container">
                                <div className="fs_date"><span className="fs_d">23</span><span className="fs_mon">MAY</span></div>
                                <div className="fs_details">
                    	<span>
                            <p>EID AL-FITR</p>
                            <h1>FESTIVAL OF BREAKING FAST</h1>
                        </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="fs_countdown">
                                <li>224 <span>DAYS</span></li>
                                <li>05 <span>HOURS</span></li>
                                <li>23 <span>MIN</span></li>
                                <li>10 <span>SEC</span></li>
                            </div>
                        </div>

                    </div>


                    <div className="fs_listing_sec">
                        <a className="fs_listing" href="#">
                            <div className="fs_left_col">
                                <h2>FIRST EID JAM'AT</h2>
                                <span></span>
                                <h2>8:45 AM EST</h2>
                            </div>


                            <div className="fs_right_col">
                                <div className="fc_dt">
                                    <span><i className="fas fa-map-marker-alt"></i></span>
                                    <p>PROSPECT PARK - PARADE GROUND #8 (ARGYLE RD & CATON EVE)</p>
                                </div>

                                <div className="fc_dt">
                                    <span><i className="fas fa-bullhorn"></i></span>
                                    <p>PROSPECT PARK - PARADE GROUND #8 (ARGYLE RD & CATON EVE)</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </a>

                        <a className="fs_listing" href="#">
                            <div className="fs_left_col">
                                <h2>SECOND EID JAM'AT</h2>
                                <span></span>
                                <h2>9:15 AM EST</h2>
                            </div>


                            <div className="fs_right_col">
                                <div className="fc_dt">
                                    <span><i className="fas fa-map-marker-alt"></i></span>
                                    <p>PROSPECT PARK - PARADE GROUND #8 (ARGYLE RD & CATON EVE)</p>
                                </div>

                                <div className="fc_dt">
                                    <span><i className="fas fa-bullhorn"></i></span>
                                    <p>PROSPECT PARK - PARADE GROUND #8 (ARGYLE RD & CATON EVE)</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </a>

                    </div>

                    <div className="fs_note">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <p><strong>NOTE:</strong> We encourage everyone to bring sweets to share with guests. Please let us
                                    know beforehand. </p>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <p className="update_info">Last updated: oct 11, 2019 / 12:43 AM</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default EidTimeCounter;


