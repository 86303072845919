import React, { useEffect, useState } from 'react';
import BreadCrumbSection from "../BreadCrumbSection/BreadCrumbSection";
import RecentNews from "../RecentNews/RecentNews";
import Socialicons from "../Socialicons/Socialicons";
import Ad336X280 from "../Ad336X280/Ad336X280";
import HomePageWeeklyProgSection from "../HomePageWeeklyProgSection/HomePageWeeklyProgSection";
import ServicesList from "../ServicesList/ServicesList";
import Ad728X90 from "../Ad728X90/Ad728X90";
import Helmet from 'react-helmet';
import moment from "moment";
import { serialize } from "../../utils/markdowntoreact";
import LatestEventsSidebar from "../Common/LatestEventsSidebar.js";
import LatestVideosSidebar from "../Common/LatestVideosSidebar.js";
import { VideoPlayerModal } from "master-react-lib";
import { getOrganization } from '../Home/apis';

var baseUrl = 'http://localhost:3000/';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function NewsDetails(props) {
    const [news, setnews] = useState({});
    const [videoId, setVideoId] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [showFeaturePages, setShowFeaturePages] = useState({});
    const [facebookUrl, setFacebookurl] = useState('');
    const [youtubeUrl, seYoutubeUrl] = useState('');

    useEffect(() => {
        const getData = async () => {
            try {
                const resAnn = await getOrganization(orgId)
                const resData = resAnn?.data?.body?.data ?? {};
                setFacebookurl(resData.orgSocials.facebook);
                seYoutubeUrl(resData.orgSocials.youtube)

                console.log("orgSocials Events: ", resData.orgSocials)
              } catch (err) {   
                console.log(err)
              }
        }
        getData();

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        if (props?.location?.state?.news) {
            setnews(props?.location?.state?.news)
            console.log("props?.location?.state?.news", props?.location?.state?.news)
        }
        if (props?.location?.state?.showFeaturePages) {
            setShowFeaturePages(props?.location?.state?.showFeaturePages);
            
        }
    }, []);

    const getVideoId = (videoId) => {
        if (videoId) {
            setVideoId(videoId)
            setShowVideo(true)
        }

    }

    let x = news?.description && JSON.parse(news?.description);
    let editor;
    if (x) {
        editor = { children: [...x] }
    }
    const description = news?.description && news?.description?.includes('"type":') ? serialize(editor) : news?.description;
    let formatDate = news?.createdAt ? moment(news?.createdAt).format("LLLL") : "";

    const additionalContent = news?.additionalContent?.map((el, index) => {
        switch (el?.type) {
            case 'video':
                return (<iframe key={index} src={el?.value ?? ""} style={{ width: "100%", minHeight: "500px" }}></iframe>)

            case 'image':
                return <img key={index} style={{ width: "100%", minHeight: "500px" }} src={el?.value?.imageUrl ?? ""} />

            case 'text':
                let x = el?.value && JSON.parse(el?.value);
                let editor;
                if (x) {
                    editor = { children: [...x] }
                }
                const description = el?.value && el?.value?.includes('"type":') ? serialize(editor) : el?.value;
                return <p dangerouslySetInnerHTML={{ __html: description }}></p>
        }

    })


    const newsDetailsProps = {
        facebook : {facebookUrl},
        youtube: {youtubeUrl}
    }
    return (
        <div>

            <div className="fixed_bg"></div>
            <               div className="wrapper body">

                <Helmet>
                    <title>Darul Jannah - News Details | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="READS/VIEWS DETAILS" b_link="READS/VIEWS DETAILS" />

                {/*-------------- EVENTS AND RECENT NEWS ------------*/}

                <div className="section">

                    <div className="container">
                        <div className="news_content">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">

                                    <div className="nws_d_s">

                                        <img className="img_responsive" src={news?.imageMetaData?.imageUrl ?? ""} />

                                        <div className="nws_content">
                                            <h1>{news.title}</h1>

                                            <div className="nws_data_box">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="post_date"><i
                                                            className="far fa-calendar"></i> {formatDate}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="sidebar_social">
                                                            <a href="https://www.facebook.com/DarulJannahMasjid/" target="blank"><i className="fab fa-facebook-f"></i></a>
                                                            {/* <a href="#"><i className="fab fa-twitter"></i></a>
                                                            <a href="#"><i className="fab fa-youtube"></i></a> */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <p dangerouslySetInnerHTML={{ __html: description }}></p>

                                            {additionalContent}
                                        </div>

                                    </div>

                                </div>


                                {/*--------------------- SIDEBAR START -----------------------*/}
                                <div className="col-lg-4 col-md-12">
                                {showFeaturePages?.videos?.enablePage &&
                                    <>
                                        <div className="sec_title_group">
                                            <h2 className="section_title">RECENT VIDEOS</h2>
                                        </div>

                                        <LatestVideosSidebar getVideoId = {getVideoId}/>
                                        <VideoPlayerModal
                                            isOpen={showVideo}
                                            onClose={() => setShowVideo(false)}
                                            vidoeID={videoId}
                                        />
                                    </>
                                }
                                    <div className="sidebar_section">
                                        <div className="sidebar_title">
                                            <h2>GET CONNECTED</h2>
                                        </div>
                                        {newsDetailsProps && <Socialicons orgSocials = {newsDetailsProps}/>}
                                    </div>
                                    {showFeaturePages?.events?.enablePage &&
                                        <LatestEventsSidebar news = {news}/>
                                    }



                                    {/*-------------------- AD ---------------------*/}
                                    <Ad336X280 />


                                </div>
                                {/*----------------- END SIDEBAR --------------------*/}


                            </div>

                        </div>


                    </div>

                </div>

                {/*----------- WEEKLY PROGRAM SECTION --------------- */}
                <HomePageWeeklyProgSection />
                {/*---------------- Services List --------------------*/}
                {showFeaturePages?.services?.enablePage &&
                    <ServicesList />
                }

                {/* <div className="section">                             
                <Ad728X90 base_url={baseUrl}/>
             </div>   */}

                <div className="footer_space"></div>


            </div>
        </div>
    );
}

export default NewsDetails;