import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import Helmet from "react-helmet";
import BreadCrumbSection from '../BreadCrumbSection/BreadCrumbSection';
import { getOrganization } from "../Home/apis";
import { serialize } from "../../utils/markdowntoreact";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

const TermsPolicies = (props) =>  {

        useEffect(() => {
            window.scrollTo({top:0, left: 0, behavior: 'smooth'});
        });

        const [organization, setorganization] = useState({})

        useEffect(() => {
            window.scrollTo({top:0, left: 0, behavior: 'smooth'});
            const getData = async () => {
                try {
                    const resAnn = await getOrganization(orgId)
                    const resData = resAnn?.data?.body?.data ?? {};
                    if(resData?.enabledIntegrations?.deenweb?.pages?.termsOfServices?.status !== "active") {
                        props.history.push("/");
                    } else {
                        console.log(resData, resAnn, "org")
                        setorganization(resData)
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            getData();
        }, [])

        const formatEditorData = (text) => {
            if(!text) return null
            let x = text && JSON.parse(text);
            let editor;
            if(x) {
            editor = { children: [ ...x ] }
            }

            return text && text?.includes('"type":') ? serialize(editor) : text;
        }

        const tosDescription = formatEditorData(organization?.enabledIntegrations?.deenweb?.settings?.termsOfService?.description);




        return (

            <div>

            <div className="fixed_bg"></div>
<               div className="wrapper body">
                {/*------- Update website title -------*/}
                <Helmet>
                    <title>Darul Jannah - Terms of services - Darul Jannah | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="Terms of services" b_link="Terms of services"/>


                {/*-------------- TAB SECTION ----------------*/}
                <ul className="tab_inner">
                    <li className="menulinks"><Link className="active" to="/TermsPolicies"> TERMS OF SERVICE </Link></li>
                    
                    {organization?.enabledIntegrations?.deenweb?.pages?.privacyPolicy?.enablePage  &&
                        <li className="menulinks"><Link className="" to="/PrivacyPolicy"> Privacy Policy </Link></li>
                    }
                </ul>

                {/*-------------- BODY SECTION ----------------*/}

                <div className="tos_container">
                    {tosDescription ? <div dangerouslySetInnerHTML={{ __html: tosDescription }}></div> : null }


                    {/* <h1 className="h_title">Terms of services</h1>
                    <p className="date">Last updated: Aug 10, 2020</p>


                    <div className="content_section">
                        <h2>WHO IS Darul Jannah</h2>
                        <p>DEENFUND (hereinafter, referred to as “DEENFUND”, “we” or “us”) Online donation platform for Not-for-profit Organizations. 
                            The project is registered as an entity itself. It’s also a product of a software development company. NextGen Development, Inc. 
                            NextGen Development has the rights to use the data from DEENFUND for other product lines and marketing purpose.</p>


                            <div className="address_sec">
                                <h3>Official Company Name: <span>DEENFUND Inc.</span></h3>
                                <h3>Postal Address: <span>77 Broad St. New York, NY 10004</span></h3>
                                <h3>Email address: <span>info@daruljannah.com</span></h3>
                            </div>


                            <p>These Term of Service (the “Agreement”) are entered into and effective as of the date of your account registration with DEENFUND 
                                (“Effective Date”). This Agreement governs the relationship between DEENFUND, INC. a New York Corporation, 77 Broad St.
                                 New York, NY 10004 (“DEENFUND,” the “Company,” “we,” “our,” or “us”), and you the account holder, user, or licensee (“You” or “User) (collectively, 
                                 the “Parties”), whether as an individual or as a representative of an entity. The Agreement also covers your use of our website and our Platform as 
                                 a Service product (“Platform”), which are designed to enable organizations to accept donations directly through their website, 
                                 or through our website. </p>

                            <p>Please read the Agreement carefully before you start to use the Website. By using the Website, or by clicking to “accept” or “agree” 
                                to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by this Agreement and our 
                                Privacy Policy, incorporated herein by reference. You also acknowledge and warrant, if applicable, that you have the authority to enter into 
                                this agreement on behalf of, and bind, the entity for which you will be using the Platform as a registered user. </p>

                            <p>If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.</p>


                            <h3>1 - Grant of Rights</h3>
                            <p><strong>Platform:</strong> Subject to the terms and conditions set forth herein, we hereby grant you a limited, world-wide, non-exclusive, 
                                fees-based, nontransferable right and license, under our Intellectual Property Rights</p>


                            <ul className="list">
                                <li>(i) Use, in the form in which such Platform is delivered by the DEENFUND only</li>
                                <li>(ii) Utilize such Platform only for the purpose of receiving donations.</li>
                            </ul>

                            <p><strong>Limited Rights of License:</strong> Your obtainment and/or use of the Platform does not grant you any rights of ownership in the Platform, 
                                as all rights granted are merely those of a licensee under the terms of this Agreement, with those rights granted only for such time as:</p>

                            <ul className="list">
                                <li>a. You conform to the terms and conditions of this Agreement</li>
                                <li>b. Until the termination of this Agreement.</li>
                            </ul>


                            <p><strong>No Other Rights:</strong> Except for the limited rights expressly granted under this Agreement, neither this Agreement nor Your exercise of rights 
                                granted convey any other rights or license to the Platform, Confidential Information, Documentation, and Marks whether by implication.
                                 We retain all rights, title, and interest in and to the Platform, Confidential Information, Documentation, and Marks (as defined below), 
                                 including without limitation, all worldwide right, title and interest in and to all patents and all filed, pending applications for patents, 
                                 including any reissue, reexamination, divisional, continuation or continuation-in-part patent applications now or hereafter 
                                 filed (“Patent Rights”);</p>

                            <ul className="list">
                                <li>a. All trade secrets, and all trade secret rights and equivalent rights arising</li>
                                <li>b. All works of authorship, including all registered and unregistered copyrights</li>
                                <li>c. All proprietary indicia, trademarks, trade names, symbols, logos and/or brand names (“Marks”), in each case</li>
                                <li>d. As the same may arise or exist under common law, state law, federal law and laws of foreign countries (collectively “Intellectual Property Rights”).</li>
                            </ul>


                            <h3>2 - Payments</h3>

                            <p><strong>Transaction Fee.</strong> The fees owed with respect to the rights and licenses granted hereunder shall be no more than 4 percent (%4.000) per unique credit 
                                card donation. A bill will be created once a month with those aggregate fees. These fees do not include Stripe’s processing fees, which will 
                                also be deducted immediately at the time of the transaction processing.</p>

                            <p><strong>Taxes:</strong> The fees charged do not include any foreign, federal, state or local sales, use or other similar taxes, however designated, 
                                levied against the sale, licensing, delivery or use of the Platform. You agree to pay, or reimburse, DEENFUND for all such taxes imposed, 
                                provided, however, that you shall not be liable for any taxes based on DEENFUND net income. You agree to promptly notify us of any amendment or 
                                revocation of such certificate, and will hold DEENFUND harmless from and against any taxes or other monies resulting from the failure to hold a 
                                certificate recognized as valid by the appropriate tax authority.</p>

                            <p><strong>Stripe:</strong> Stripe is a secure, modern payment processor trusted by major tech companies such as Lyft, Shopify, and Kickstarter. 
                                It gives you the ability to accept cards, low fee ACH bank donations, and Apple Pay. Best of all, the signup process only takes 5 
                                minutes and there are no monthly charges. DEENFUND is a state of the art donation and fundraising platform with native integration with Stripe. 
                                It helps you easily to accept one-time and recurring donations from donors. With DEENFUND, you won't need to hire a developer to integrate with 
                                the Stripe API. PCI compliance and card saving security are also taken care of for you. Your donation forms can be localized in 8 languages and 20 
                                major currencies. The project is available to any organization/any donor from anywhere in the world. DEENFUND gives your donors the ability to 
                                update expired cards and edit their recurring plans at any time. DEENFUND is a powerful stripe donation plugin that is super simple to setup 
                                and attracts more recurring donors. DEENFUND is used by nonprofits, churches, universities, politicians, and medical research organizations. 
                                Contact us at info@deenfund.com in case of any difficulties or technical issues.</p>


                            <h3>3 - Your Additional Obligations</h3>

                            <p><strong>Performance Obligations.</strong> You shall not alter, re-label or change the Platform as provided by DEENFUND, and as may be amended at any time at 
                                our sole discretion, without our prior written consent.</p>

                            <p><strong>Books and Records.</strong> You agree to keep and maintain accurate books and records as reasonably necessary for verification of transaction payments
                                 pursuant to this Agreement.</p>

                            <p><strong>Terms and Conditions of Card Processor (Stripe).</strong> You agree at all times to conform to The terms and conditions of credit card processing as 
                                defined by Stripe.com, which terms and conditions may be amended from time-to-time at the sole discretion of Stripe.</p>


                            <h3>4 - Disclaimer of Warranties. </h3>

                            <p>The Website is provided “as is”. DEENFUND and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, 
                                including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. 
                                Neither DEENFUND nor its suppliers and licensors, makes any warranty that the Website will be error free or that access thereto will be 
                                continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Website at your 
                                own discretion and risk.</p>


                            <p><strong>Indemnification.</strong> You agree to indemnify and hold harmless DEENFUND, its contractors, and its licensors, and their respective directors, 
                                officers, employees and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of 
                                the Website, including but not limited to your violation of this Agreement.</p>

                            <h3>5 - Terms and Termination</h3>

                            <p><strong>Termination.</strong> DEENFUND may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, 
                                effective immediately. If you wish to terminate this Agreement or your TopNonprofits.com account (if you have one), you may simply discontinue 
                                using the Website. DEENFUND can terminate the Website immediately as part of a general shut down of our service. All provisions of 
                                this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership 
                                provisions, warranty disclaimers, indemnity and limitations of liability. </p>

                            <p>Without limiting the foregoing, you agree to not use the Services to establish or contribute to any Organizations with the implicit or express 
                                purpose relating to any of the following:</p>


                            <ul className="list outside">
                                <li>Any activity that violates any law or governmental regulation;</li>
                                <li>Organizations that are fraudulent, misleading, inaccurate, dishonest, impossible or imitating any other person or fundraising campaign without permission (whether on the Platform or not);</li>
                                <li>The promotion of hate, violence, harassment, discrimination, terrorism, or intolerance of any kind, including by race, ethnicity, national origin, religious affiliation, sexual orientation, sex, gender or gender identity, or serious disabilities or diseases;</li>
                                <li>Illegal drugs, narcotics, steroids, controlled substances or other products that present a risk to consumer safety or any related paraphernalia;</li>
                                <li>Ransom, human trafficking or exploitation;</li>
                                <li>Knives, explosives, ammunition, firearms, or other weaponry or accessories;</li>
                                <li>Gambling, gaming and/or any other activity with an entry fee and a prize, including, but not limited to casino games, sports betting, fantasy sports, horse or greyhound racing, lottery tickets, other ventures that facilitate gambling, games of skill or chance (whether or not it is legally defined as a lottery) or sweepstakes;</li>
                                <li>Offensive, graphic, perverse or sensitive content, including pornography or other sexual content;</li>
                                <li>Annuities, investments, equity or lottery contracts, lay-away systems, off-shore banking or similar transactions, money service businesses (including currency exchanges, check cashing or the like), debt collection or crypto-currencies;</li>
                                <li>Offering monetary rewards, including gift cards, without declaring fair market value (FMV) of goods exchanged;</li>
                                <li>Transactions for the sale of items before the seller has control or possession of the item;</li>
                                <li>Collection of payments on behalf of merchants by payment processors or otherwise;</li>
                                <li>Credit repair or debt settlement services.</li>
                            </ul>


                            <p><strong>Termination for Insolvency.</strong> This Agreement shall terminate, without notice:</p>

                            <ul className="list">
                                <li>a. You conform to the terms and conditions of this Agreement</li>
                                <li>b. Until the termination of this Agreement.</li>
                            </ul>



                    </div>
 */}

                </div>
               
            </div>
            </div>

    );
}

export default TermsPolicies;