import React from 'react';
import BreadCrumbSection from "../BreadCrumbSection/BreadCrumbSection";
import Helmet from "react-helmet";
import EidTimeCounter from "../EidTimeCounter/EidTimeCounter";
import EidGallery from "../EidGallery/EidGallery";
import RecentNews from "../RecentNews/RecentNews";
import Ad728X90 from "../Ad728X90/Ad728X90";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Socialicons from "../Socialicons/Socialicons";
import EidOrganizer from "../EidOrganizer/EidOrganizer";
import HomePageWeeklyProgSection from "../HomePageWeeklyProgSection/HomePageWeeklyProgSection";
import ServicesList from "../ServicesList/ServicesList";
import LatestEventsSidebar from "../Common/LatestEventsSidebar.js"


class Eid extends React.Component {
    render(){
        return (
            <div className="wrapper">

                {/*-------------- Update website title -------------*/}
                <Helmet>
                    <title>Darul Jannah - Eid | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="EID AL-FITR" b_link="EID"/>
                {/*-------------- EID TIMER--------------------------*/}
                <EidTimeCounter />

                {/*-------------- GALLERY ---------------------------*/}
                <EidGallery/>

                <div className="section">
                    <div className="container">
                        <div className="news_content">
                            <div className="row">

                                <div className="col-lg-8 col-md-12">
                                    <div className="sec_title_group">
                                        <h1 className="section_title">SUNNA ON EID DAY</h1>
                                    </div>


                                    <div className="faq">

                                        <Accordion className="faqitem" allowZeroExpanded={true}>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        What harsh truths do you prefer to ignore?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                        Exercitation in fugiat est ut ad ea cupidatat ut in
                                                        cupidatat occaecat ut occaecat consequat est minim minim
                                                        esse tempor laborum consequat esse adipisicing eu
                                                        reprehenderit enim.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading >
                                                    <AccordionItemButton>
                                                        Is free will real or just an illusion?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                        In ad velit in ex nostrud dolore cupidatat consectetur
                                                        ea in ut nostrud velit in irure cillum tempor laboris
                                                        sed adipisicing eu esse duis nulla non.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>

                                    </div>

                                    <div className="section">

                                        {/* <Ad728X90/> */}
                                        {/*<a className="hrz_ad" href="#"><img src="assets/img/home_hr_ad.png"/></a>*/}

                                    </div>


                                </div>
                                {/*---------------- SIDEBAR START ------------------*/}
                                <div className="col-lg-4 col-md-12">
                                    <div className="sec_title_group">
                                        <h2 className="section_title">RECENT NEWS</h2>
                                    </div>
                                    {/*------------ Recent News ---------------------*/}
                                    <RecentNews/>

                                    

                                    <LatestEventsSidebar />


                                </div>


                            </div>

                        </div>


                    </div>
                </div>
                {/*-------------------------------*/}

                <EidOrganizer/>

                {/*----------------------- WEEKLY PROGRAM SECTION ------------------ */}
                <HomePageWeeklyProgSection /> 
                {/*----------------------- Services List ----------------------------*/}
                <ServicesList />

                {/* <div className="section">
                    <Ad728X90/>
                </div> */}


                <div className="footer_space"></div>




            </div>
        );
    }
}

export default Eid;