import React from 'react';
import Ad336X280 from '../Ad336X280/Ad336X280';

function BecomeMember() {
    return (
        <div className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="becom_member_container">
                            <div className="pattren_right">
                                <img src="assets/img/ms_pattren.png" />
                            </div>
                            <div className="becom_member_content">
                                <h1>Become a supporter</h1>
                                <p>The Darul Jannah Masjid is open for the 5 daily prayers including the Friday Jumu’ah
                                    prayer and khutbah.
                                    The Muslim greeting of salam “saying as-salamu alaykum” in Arabic means “peace be
                                    upon you”.</p>
                                <div className="btn_white"><a target="blank" href={localStorage.getItem("donationUrl") ?? '#'}>DONATE NOW</a></div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-12">
                        <Ad336X280 />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default BecomeMember;
