import React from 'react'

export default function AboutUsContactSection({organization}) {
        return (
                <div className="container">
                    <div className="sec_title_group">
                        <h1 className="section_title">CONTACT US</h1>
                    </div>

                    <div className="contact_details">
                        <div className="col_img"><img className="img_responsive" src="assets/img/contact_img.png" /></div>
                        <div className="col_detail">

                            <div className="contact_info_sec">
                                <h2>ADDRESS</h2>
                                <span className="icon"><i className="fas fa-map-marked-alt"></i></span>
                                <span className="ctc_info"> {organization?.orgAddress?.line1 ?? ""} <br/> {organization?.orgAddress?.city ?? ""}, {organization?.orgAddress?.state ?? ""} {organization?.orgAddress?.postalCode ?? ""}</span>
                                <div className="clearfix"></div>
                            </div>

                            <div className="contact_info_sec">
                                <h2>PHONE</h2>
                                <span className="icon"><i className="fas fa-phone-alt"></i></span>
                                <span className="ctc_info"> {organization?.phoneFax?.primaryPhone ?? ""} <br/> {/* {organization?.phoneFax?.secondaryPhone ?? ""} */}</span> 
                                <div className="clearfix"></div>
                            </div>

                            <div className="contact_info_sec">
                                <h2>EMAIL</h2>
                                <span className="icon"><i className="fas fa-envelope"></i></span>
                                <span className="ctc_info">{organization?.orgEmail ?? ""}</span>
                                <div className="clearfix"></div>
                            </div>

                        </div>

                        <div className="clearfix"></div>
                    </div>
                </div>
        );
}