import React, {useState, useEffect} from "react";
import RecentImgs1 from '../../Assets/imgs/recentImg1.png'
import RecentImgs2 from '../../Assets/imgs/recentImg2.png'
import RecentImgs3 from '../../Assets/imgs/recentImg3.png'
import RecentImgs4 from '../../Assets/imgs/recentImg4.png'
import YoutubeIcon from '../../Assets/imgs/youtube_icon.png'
import {Link, withRouter} from "react-router-dom";
import { serialize } from "../../utils/markdowntoreact";
import { getVideos } from "../Home/apis";

import moment from 'moment';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function LatestVideosSidebar(props) {
    const [videos, setvideos] = useState([])
    const [isOpen, setOpen] = useState(false);
    const [videoId, setVideoId] = useState(null);


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            try {
                let resVideo = await getVideos(orgId, "deenweb")                
                resVideo = resVideo?.data?.body?.data ?? [];
                // console.log("videos", resVideo);
                setvideos(resVideo)
    
              } catch (err) {
                console.log(err)
              }
        }
        getData();
    }, [])


    const videosData = videos?.length ? videos.slice(0, 4).map(({video}, i) => {
        let formatDate = moment(video?.createdAt).format("MMMM DD, YYYY");
        let thumbnail = video?.link ?? null;
        let videoId = thumbnail?.split("/embed/")?.[1] ? thumbnail?.split("/embed/")?.[1] : null;
        if(thumbnail) {
            thumbnail = thumbnail?.split("/embed/")?.[1] ? 
                `https://img.youtube.com/vi/${thumbnail?.split("/embed/")?.[1]}/sddefault.jpg` :
                YoutubeIcon
        } else {
            thumbnail = YoutubeIcon;
        }

        return <li key={i} className="nav-item" onClick={() => props.getVideoId(videoId)}><a className="nav-link" href="#dashboard-1"
            role="tab" data-toggle="tab">
            <div className="v_list">
            <div className="v_list_thumb">
            <img className="vd_tumb" src={thumbnail} alt="videos" /></div>
            <div className="v_details">
            <div className="post_date"><i
            className="far fa-calendar"></i> {formatDate}
            </div>
            <h5>{video.title}</h5>
            </div>
            <div className="clearfix"></div>
            </div>
            </a>
        </li>
    }) : null;
    
    return (
        <div className="sidebar_section news_details">
            <ul className="nav nav-pills nav-pills-icons tab_listing" role="tablist">
               
               {videosData}
               

                {/* <li className="nav-item"><a className="nav-link active" href="#dashboard-1"
                                            role="tab" data-toggle="tab">
                    <div className="v_list">
                        <div className="v_list_thumb">
                            <img className="vd_tumb" src={RecentImgs1} alt="videos" /></div>
                        <div className="v_details">
                            <div className="post_date"><i
                                className="far fa-calendar"></i> Oct 30, 2019
                            </div>
                            <h5>Bracing the Perfect Storm with ad Air of Calm</h5>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </a></li>

                <li className="nav-item"><a className="nav-link" href="#schedule-1"
                                            role="tab" data-toggle="tab">
                    <div className="v_list">
                        <div className="v_list_thumb"><img className="vd_tumb" src={RecentImgs2} alt="videos" /></div>
                        <div className="v_details">
                            <div className="post_date"><i
                                className="far fa-calendar"></i> Oct 30, 2019
                            </div>
                            <h5>Bracing the Perfect Storm with ad Air of Calm</h5>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </a></li>
                <li className="nav-item"><a className="nav-link" href="#tasks-1" role="tab"
                                            data-toggle="tab">
                    <div className="v_list">
                    <div className="v_list_thumb"><img className="vd_tumb" src={RecentImgs3} alt="videos" /></div>
                        <div className="v_details">
                            <div className="post_date"><i
                                className="far fa-calendar"></i> Oct 30, 2019
                            </div>
                            <h5>Bracing the Perfect Storm with ad Air of Calm</h5>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </a></li>
                <li className="nav-item"><a className="nav-link" href="#tasks-2" role="tab"
                                            data-toggle="tab">
                    <div className="v_list">
                    <div className="v_list_thumb"><img className="vd_tumb" src={RecentImgs4} alt="videos" /></div>
                        <div className="v_details">
                            <div className="post_date"><i
                                className="far fa-calendar"></i> Oct 30, 2019
                            </div>
                            <h5>Bracing the Perfect Storm with ad Air of Calm</h5>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </a></li>
           */}
            </ul>
        </div>
    );
}
export default withRouter(LatestVideosSidebar);

