import React from 'react';
import moment from "moment";
import { Link } from 'react-router-dom';
import { serialize } from "../../utils/markdowntoreact";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Grid from '@material-ui/core/Grid';

function UpcomingEvents({ events, news }) {

    const eventsData = events?.length ? events.slice(0, 3).map(({event}, i) => {
        let startDateTime = moment(event?.eventStart?.startAt);
        let endDateTime = moment(event?.endAt);
        let formatDate = startDateTime.format("DD MMMM, YYYY");
        let formatEndDate = endDateTime.format("DD MMMM, YYYY");
        if(formatDate === formatEndDate) {
            formatEndDate = null;
        }

        
        formatDate = formatDate?.split(/ (.*)/s);
        let formatTime = startDateTime.format("hh:mm a");
        let formatEndTime = endDateTime.format("hh:mm a");

        let x = event?.description && JSON.parse(event.description);
        let editor;
        if(x) {
         editor = { children: [ ...x ] }
        }

        const description = event?.description && event.description?.includes('"type":') ? serialize(editor) : event?.description;

        return <div className="card_left">
        {event?.imageDetails?.imageUrl && <img className="card_event img_responsive" src={event?.imageDetails?.imageUrl ?? ""} />}
        <div className="card_details">
            <div className="event_date">
                <div className="day_large">{formatDate?.[0] ?? ""}</div>
                <div className="ev_date_small">
                    <div className="date_tp">{formatDate?.[1] ? formatEndDate ? `${formatDate?.[1]}  to   ${formatEndDate}`: formatDate?.[1] :   ""}</div>
                    <div className="time_btm">{formatTime} to {formatEndTime}</div>
                </div>
                <div className="clearfix"></div>
            </div>
            <h4>{event.title}</h4>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
            <Link className="read_more" to={{ 
                    pathname: `/event-details/${event.title.toLowerCase().replace(/ /g, "-")}`,
                    state: { event: event, news: news } 
                }}>VIEW DETAILS <ArrowRightAltIcon />
            </Link>
        </div>
    </div>
    }) : null;

    



    // console.log("eventsData", eventsData)

    return (
        <div>
            <div className="section">
                {events?.length ? <div className="container">
                    <div className="sec_title_group">
                        <h1 className="section_title">UPCOMING DARUL JANNAH EVENTS</h1>
                    </div>
                    <div className="UpcomingEventsContainer">
                    {eventsData.slice(0, 2)}
                    </div>

                    <div className="UpcomingEventsContainer">
                        {eventsData?.length > 2 &&
                            eventsData[2]
                        }
                    </div>
                    <div className="more_view">
                        <Link to={{ 
                                pathname: "/event-list", 
                                state: { events: events, news: news } 
                            }}>VIEW MORE EVENTS
                         </Link>
                    </div>
                    <div className="clearfix"></div>
                </div> :  null}
            </div>
        </div>
    );
}

export default UpcomingEvents;