import React, { useState, useEffect } from 'react';
import BreadCrumbSection from '../BreadCrumbSection/BreadCrumbSection';
import Socialicons from "../Socialicons/Socialicons";
import AboutGallery from "../AboutGallery/AboutGallery";
import BecomeMember from "../BecomeMember/BecomeMember";
import Ad336X280 from "../Ad336X280/Ad336X280";
import OurServicesAboutUs from "../OurServicesAboutUs/OurServicesAboutUs";
import AboutUsContactSection from "../AboutUsContactSection/AboutUsContactSection";
import LetsConnectForm from "../LetsConnectForm/LetsConnectForm";
import GetDirection from "../GetDirection/GetDirection";
import Ad728X90 from "../Ad728X90/Ad728X90";
import Helmet from "react-helmet";
import { getOrganization } from "../Home/apis";
import { serialize } from "../../utils/markdowntoreact";
import SuccessAlert from '../Common/SuccessAlert';
import { getPhotos } from '../Home/apis';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

export default function AboutUs(props) {
    const [organization, setorganization] = useState({})
    const [open, setopen] = useState(false)
    const [email, setemail] = useState("")
    const [scrollTo, setscrollTo] = useState(props?.location?.state?.section ?? false);
    const [scrollToTab, setscrollToTab] = useState(false);
    const [showServiceSection, setServiceSection] = useState(true);
    const [photoGallery, setPhotoGallery] = useState([]);
    //const [orgSocials, setOrgSocials] = useState([]);
    const [facebookUrl, setFacebookurl] = useState('');
    const [youtubeUrl, seYoutubeUrl] = useState('');
    const [twitterUrl, seTwitterUrl] = useState('');

    useEffect(() => {
        const getData = async () => {
            try {
                const resAnn = await getOrganization(orgId);
                const resData = resAnn?.data?.body?.data ?? {};
                const orgSocials = resAnn.data?.body?.data?.orgSocials

                console.log("orgSocials in UseEfect: ", orgSocials)
                console.log("resData.orgSocials: ", resData.orgSocials)
                //console.log("org", resData )
                if (resData?.enabledIntegrations?.deenweb?.pages?.aboutUs?.status === "inactive") {
                    props.history.push("/")
                }
                let Images = [];
                let photos = await getPhotos(orgId, "deenweb");
                photos = photos?.data?.body?.data ?? [];
                if (photos.length) {
                    photos = photos.sort((a, b) => a.order - b.order);

                    photos.forEach(data => {
                        const temp = {
                            title: data?.title,
                            image: data?.imageMetaData,
                            description: data?.description
                        };
                        Images.push(temp);
                    });
                };
                setPhotoGallery(Images);
                setorganization(resData)

                setFacebookurl(resData.orgSocials.facebook);
                seYoutubeUrl(resData.orgSocials.youtube)
                seTwitterUrl(resData.orgSocials.twitter)

                console.log("organization: ", organization)
                console.log("facebookUrl: ", facebookUrl)
                console.log("youtubeUrl: ", youtubeUrl)
                console.log("twitterUrl: ", twitterUrl)

                setServiceSection(resData?.enabledIntegrations?.deenweb?.pages?.services?.enablePage);
            } catch (err) {
                console.log(err)
            }
        }
        getData();
    }, []);
    useEffect(() => {
        if (props?.location?.state?.section) {
            setscrollTo(props?.location?.state?.section);
        }

        if (props?.location?.state?.tab) {
            setscrollToTab(props?.location?.state?.tab);
        }
    }, [props])


    useEffect(() => {
        if (!scrollTo)
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    const formatEditorData = (text) => {
        if (!text) return
        let x = text && JSON.parse(text);
        let editor;
        if (x) {
            editor = { children: [...x] }
        }

        return text && text?.includes('"type":') ? serialize(editor) : text;
    }

    const showAlert = (value, email) => {
        setopen(value)
        setemail(email)

        setTimeout(() => {
            setopen(false)
        }, 5000);
    }
    const orgSocialLinks = {
        facebook: { facebookUrl },
        youtube: { youtubeUrl },
        twitter: { twitterUrl }
    }


    return (
        <div>
            <div className='SuccessAlertBox'>
                {open && <SuccessAlert msg={`Thank You For Contacting Us! You will receive an email confirmation shortly at ${email}`} open={open} setOpen={setopen} />}
            </div>


            <div className="fixed_bg"></div>
            <div className="wrapper body">

                {/*------- Update website title -------*/}
                <Helmet>
                    <title>Darul Jannah - About Us | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="ABOUT US" b_link="ABOUT" />

                {/*--------- ABOUT DETAILS -------------*/}
                <div className="section">
                    <div className="container">
                        <div className="sec_title_group">
                            <h1 className="section_title">WELCOME TO DARUL JANNAH</h1>
                        </div>

                        <img className="img_responsive" src="assets/img/about_img.png" />

                        <div className="about_detail">

                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    {/* <h3>Darul Jannah is a 501(C) not-for-profit religious organization. IT was
                                            incorporated in the State of New York. </h3> */}

                                    <div dangerouslySetInnerHTML={{ __html: formatEditorData(organization?.aboutOrg?.aboutUsShort) }}></div>

                                    <div dangerouslySetInnerHTML={{ __html: formatEditorData(organization?.aboutOrg?.aboutUsLong) }}></div>

                                    {/* {organization?.aboutOrg?.orgMission?.enabled &&  */}
                                    <div dangerouslySetInnerHTML={{ __html: formatEditorData(organization?.aboutOrg?.orgMission?.content) }}></div>
                                    {/* } */}

                                    {/* {organization?.aboutOrg?.orgVision?.enabled &&  */}
                                    <div dangerouslySetInnerHTML={{ __html: formatEditorData(organization?.aboutOrg?.orgVision?.content) }}></div>
                                    {/* } */}

                                    {/* <p>The present facility located at 722 Church Avenue. Brooklyn, New York was pur
                                            chased in 2014 and renovated.
                                            Since then BIC has been serving the Muslim community of Kensington and
                                            Flatbush areas and beyond in Brooklyn.</p>

                                        <p>By the grace of Allah on 29 May 2014 BIC purchased 12,000 Square Feet Plot at
                                            722 Church Avenue, Brooklyn, New York 11218, in order to build a
                                            multipurpose facility, which will serve the multifaceted needs of the
                                            community, It was possible due to fund generated through the generous
                                            contribution of sadaqah and qard-i-hasanah [ non-interest bearing loadn ] by
                                            the Muslim community of Brooklyn.</p>


                                        <h2>OUR MISSION & VISION</h2>
                                        <p>Our mission is, “To provide multifaceted services to the Muslim community of
                                            Brooklyn and beyond
                                            that will fulfill their religious, social, cultural, educational, and
                                            recreatinal needs through
                                            building and maintaining appropriate facilities.”</p> */}

                                </div>


                                <div className="col-lg-4 col-md-12">
                                    <div className="sidebar">

                                        <div className="sidebar_section">
                                            <div className="sidebar_title">
                                                <h2>GET CONNECTED</h2>
                                            </div>
                                            <Socialicons orgSocials={orgSocialLinks} />
                                        </div>

                                        {photoGallery.length > 0 &&
                                            <div className="sidebar_section">
                                                <div className="sidebar_title">
                                                    <h2>PHOTO GALLERY</h2>
                                                </div>

                                                <AboutGallery photoGallery={photoGallery} />

                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*------------------- Become Member SECTION START ----------------*/}
                <BecomeMember />

                {/*-------------------- OUR SERVICES ----------------------*/}
                {showServiceSection &&

                    <div className="section">

                        <OurServicesAboutUs scrollToTab={scrollToTab} />

                    </div>
                }

                {/*------------------- CONTACT US SECTION -----------------*/}
                <div className="section">
                    <AboutUsContactSection organization={organization} />
                </div>
                {/*------------------- LETS CONNECT SECTION ---------------*/}
                <LetsConnectForm organization={organization} showAlert={showAlert} scrollTo={scrollTo} />
                {/*------------------- GET DIRECTION SECTION --------------*/}
                <GetDirection organization={organization} scrollTo={scrollTo} />

                {/*------------------- AD ---------------------------------*/}
                {/* <div className="section">
                    <Ad728X90/>
                </div> */}

                <div className="footer_space"></div>

                {/*--------------------------------------------------------*/}


            </div>

        </div>
    );
}
