import React from 'react';

class Socialicons extends React.Component {
    
    render(){
        //console.log("OrgSocials Social Icons: ", this.props.orgSocials)
        const orgSocialLinks = this.props.orgSocials
        return (
            <div className="sidebar_social">
               { orgSocialLinks.facebook.facebookUrl &&  <a href={orgSocialLinks.facebook.facebookUrl} target="_blank"><i className="fab fa-facebook-f"></i></a> }
                { orgSocialLinks.youtube.youtubeUrl &&  <a href={orgSocialLinks.youtube.youtubeUrl} target="_blank"><i class="fab fa-youtube"></i></a> }
                {/* { this.props.orgSocials.twitter &&  <a href={this.props.orgSocials.twitter} target="_blank"><i className="fab fa-twitter"></i></a> } */}
            </div>
        );
    }
}

export default Socialicons;