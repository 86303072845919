import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';

// const images = [
//     "1.png", "2.png", "3.png", "4.png", "5.png", "6.png", 
//     "1.png", "2.png", "3.png", "4.png", "5.png", "6.png", 
//     "1.png", "2.png", "3.png", "4.png", "5.png", "6.png", 
// ];

export default function AboutGallery(props) {
    const [loadMore, setloadMore] = useState(6);
    const [images, setImages] = useState([]);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setImages(props.photoGallery);
    }, [props.photoGallery])

    const openImageSlider = (index) => {
        setIsOpen(true);
        setPhotoIndex(index);
        console.log("Index is: ", index);
        console.log("Title: ", images[index].title);
        console.log("description: ", images[index].description);
    }

    return (
        <div className="sidebar_photo_gr">
            <div className="gallery">
                {images.slice(0, loadMore).map((el, i) => {
                    return <a key={i} >  <img className="img-fluid" src={el.image.imageUrl} onClick = {() => openImageSlider(i)}/> </a>
                    //<img className="img-fluid"  src={el.image.imageUrl} onClick = {() => openImageSlider(i)}/>
                })}
            </div>
            {isOpen && (
            <Lightbox
                mainSrc={images[photoIndex].image.imageUrl}
                nextSrc={images[(photoIndex + 1) % images.length].image.imageUrl}
                prevSrc={images[(photoIndex + images.length - 1) % images.length].image.imageUrl}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={ () => setPhotoIndex((photoIndex + images.length - 1) % images.length) }
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                imageTitle = {images[photoIndex].title}
                imageCaption = {images[photoIndex].description}
            />
            )}

            {loadMore < images?.length && images?.length > 6 ? <div className="more_view show_more_btn" onClick={() => setloadMore(loadMore + 6)}>
                    <a href="javascript:void(0);">SHOW MORE</a>
            </div> : null }
            

        </div>
    );
}