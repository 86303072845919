import React from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { getPromotions } from "../Home/apis";
import { serialize } from "../../utils/markdowntoreact";

class HomePageWeeklyProgSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            details: [],
            currentSlide: {
                title: '',
                description: '',
                button: {}
            },
        };
    }

    componentDidMount() {
        const getData = async () => {
            const orgId = process.env.REACT_APP_ORGANIZATION_ID;
            const Images = [];
            const Details = [];

            const response = await getPromotions(orgId, "deenweb");
            let promotions = response?.data?.body?.data ?? [];
            promotions = promotions.filter(({ status, publishTo, publishFrom }) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
            if(promotions.length > 0 ){
                promotions = promotions.sort((a, b) => a?.promotionalSlider?.order - b?.promotionalSlider?.order);
                promotions.forEach(data => {
                    let x = data?.promotionalSlider?.description && JSON.parse(data?.promotionalSlider?.description);
                    let editor;
                    if(x) {
                        editor = { children: [ ...x ] }
                    }
                    const temp = {
                        title: data?.promotionalSlider?.title,
                        button: data?.promotionalSlider?.button,
                        description: serialize(editor)       
                    };
                    Details.push(temp);
                    Images.push({url: data.promotionalSlider.imageMetaData.imageUrl.replace(' ', '%20')});
                });
                this.setState({
                    images: [ ... Images],
                    details: [ ... Details],
                    currentSlide: {... Details[0]}
                });
            }
        }
        getData();
    }

    slidechange = (index) => {
        this.setState({
            currentSlide: {... this.state.details[index]}
        });
    }


    render(){
        // console.log('weekely componenet rendered')
        // const images = [
        //     { url: "/assets/img/prg_img.png" },
        //     { url: "/assets/img/prg_img.png" },
        //     { url: "/assets/img/prg_img.png" },
        //     { url: "/assets/img/prg_img.png" },
        //     { url: "/assets/img/prg_img.png" },
        //     { url: "/assets/img/prg_img.png" },
        //     { url: "/assets/img/prg_img.png" }
        // ];
        {console.log("this.state.images ", this.state.images)}
        return (
            <div className="section">
            {this.state.images.length > 0 ? 
                <div className="hm_btm_slider">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                            {this.state.images.length > 0 && 
                                <SimpleImageSlider
                                    width={450}
                                    height={440}
                                    showNavs={false}
                                    images={this.state.images}
                                    onClickBullets = {this.slidechange}
                                />}
                            </div>
                            <div className="col-md-7">
                                <div className="prg_detail">
                                    {/* <div className="sm_tp_title">WEEKLY PROGRAMS</div>  */}
                                    <div>
                                    <h2>{this.state.currentSlide.title}</h2>
                                    <p className="dl_text" dangerouslySetInnerHTML={{ __html: this.state.currentSlide.description }}>{/* Yasha Asley is a genius young Muslim teen who became the youngest
                                        ever employee at the University...…. */}</p>
                                        
                                    {/* <p>The Darul Jannah Masjid is open for the 5
                                        daily prayers including the Friday Jumu’ah prayer and khutbah. . . . </p> */}
                                        {this.state.currentSlide?.button?.actionButton && 
                                            <div className="btn_sec">
                                                <a className="button donate_btn" target="blank" href={this.state.currentSlide.button?.buttonLink}>{this.state.currentSlide.button?.buttonLabel}</a> 
                                            </div> 
                                        }
                                    {/* <a className="read_more" href="#">VIEW CAUSE DETAILS <ArrowRightAltIcon /></a>  */} </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            :null}
            </div>



        );
    }
}

export default HomePageWeeklyProgSection;