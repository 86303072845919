import React, { useState, useEffect } from 'react';
import { getAnnouncements } from '../Home/apis';
import moment from 'moment';
import { serialize } from "../../utils/markdowntoreact";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function HeaderNotificationSlider() {
    const [announcements, setannouncements] = useState([])
    const [showArrows, setShowArrows] = useState(true)

    useEffect(() => {
    const getData = async () => {
        try {
            const resAnn = await getAnnouncements(orgId)
            let resData = resAnn?.data?.body?.data ?? [];
            resData = resData.filter(({status, publishTo, publishFrom}) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
            setannouncements(resData);
            setShowArrows(resData.length > 1 ? true : false)

            } catch (err) {
            console.log(err)
            }
        }
        getData();
    }, [])

    const data = announcements?.length ? announcements.map(({announcement}, i) => {
        let x = announcement?.description && JSON.parse(announcement.description);
        let editor;
        if(x) {
         editor = { children: [ ...x ] }
        }

        const description = announcement?.description && announcement.description?.includes('"type":') ? serialize(editor) : announcement?.description;

        return <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
                <div className="item_contant">
                    <h1>{announcement?.title ?? ""}</h1>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                    {announcement?.button?.actionButton && <a className="whiteline_btn" href={announcement?.button?.url ?? ""}>{announcement?.button?.buttonLabel ?? ""}</a>}
                    </div>
            </div>
    }) : null;

    if(announcements.length > 0) {
        return (
            <div className="alert alert-success hdr_noti">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true"><i
                    className="material-icons">clear</i></span></button>
                <div className="container">
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
    
                            {data}
                            {/* <div className="carousel-item active">
                                <div className="item_contant">
                                    <h1>New moon of Shawwal Signted</h1>
                                    <p>In friendship diminution Instrunment so. Son sure paid door with say them. Two amang
                                        sir sorry men court. Estimable ye
                                        situation suspicion he delighted a happiness discovery.</p>
                                    <a className="whiteline_btn" href="#">BUTTON</a></div>
                            </div>
    
    
                            <div className="carousel-item">
                                <div className="item_contant">
                                    <h1>New moon of Shawwal Signted 2</h1>
                                    <p>In friendship diminution Instrunment so. Son sure paid door with say them. Two amang
                                        sir sorry men court. Estimable ye
                                        situation suspicion he delighted a happiness discovery.</p>
                                    <a className="whiteline_btn" href="#">BUTTON</a></div>
                            </div>
    
    
                            <div className="carousel-item">
                                <div className="item_contant">
                                    <h1>New moon of Shawwal Signted 3</h1>
                                    <p>In friendship diminution Instrunment so. Son sure paid door with say them. Two amang
                                        sir sorry men court. Estimable ye
                                        situation suspicion he delighted a happiness discovery.</p>
                                    <a className="whiteline_btn" href="#">BUTTON</a></div>
                            </div>
     */}
    
                        </div>
                        {showArrows  && 
                            <div className="carosel_arrow"><a className="carousel-control-prev"
                                    href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span
                                className="sr-only">Previous</span> </a> <a className="carousel-control-next"
                                    href="#carouselExampleIndicators" role="button" data-slide="next"> <span
                                className="carousel-control-next-icon" aria-hidden="true"></span> <span
                                className="sr-only">Next</span> </a>
                            </div>
                        }
                    </div>
                        
                </div>
            </div>
        );
    
    } else {
        return null;
    }
}

export default HeaderNotificationSlider;
