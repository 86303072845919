import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';


function GetDirection(props) {
    const { organization } = props;

    const [toAddress, settoAddress] = useState("")
    const [fromAddress, setfromAddress] = useState("")
    const getDirectionRef = useRef(null);

    const orgAdrress = organization?.orgAddress ? (organization.orgAddress?.line1 ?? "") + " " + (organization.orgAddress.city ?? "") + " " + (organization.orgAddress?.state ?? "") + " " + (organization.orgAddress?.postalCode ?? "") : "";

    useEffect(() => {
        const orgAdrress = organization?.orgAddress ? (organization.orgAddress?.line1 ?? "") + " " + (organization.orgAddress.city ?? "") + " " + (organization.orgAddress?.state ?? "") + " " + (organization.orgAddress?.postalCode ?? "") : "";

        settoAddress(orgAdrress)
    }, [organization])

    const submitHandler = (e) => {
        e.preventDefault();
        if(toAddress) {
            console.log("calculate", toAddress, fromAddress)

            const lat = organization?.orgAddress?.coordinatesLatLong?.lat;
            const lng = organization?.orgAddress?.coordinatesLatLong?.long;

            window.open(`https://www.google.com/maps/dir/${fromAddress}/${toAddress}`);
        }
    }


    useEffect(() => {
        if(props?.scrollTo === 'getDirection') {
            getDirectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  
        };
        // function initMap() {
        //     const map = new google.maps.Map(document.getElementById("map"), {
        //       center: { lat: 40.749933, lng: -73.98633 },
        //       zoom: 13,
        //     });
        //     const card = document.getElementById("pac-card");
        //     const input = document.getElementById("pac-input");
        //     const biasInputElement = document.getElementById("use-location-bias");
        //     const strictBoundsInputElement =
        //       document.getElementById("use-strict-bounds");
        //     const options = {
        //       componentRestrictions: { country: "us" },
        //       fields: ["formatted_address", "geometry", "name"],
        //       origin: map.getCenter(),
        //       strictBounds: false,
        //       types: ["establishment"],
        //     };
        //     map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);
        //     const autocomplete = new google.maps.places.Autocomplete(
        //       input,
        //       options
        //     );
        //     // Bind the map's bounds (viewport) property to the autocomplete object,
        //     // so that the autocomplete requests use the current map bounds for the
        //     // bounds option in the request.
        //     autocomplete.bindTo("bounds", map);
        //     const infowindow = new google.maps.InfoWindow();
        //     const infowindowContent = document.getElementById("infowindow-content");
        //     infowindow.setContent(infowindowContent);
        //     const marker = new google.maps.Marker({
        //       map,
        //       anchorPoint: new google.maps.Point(0, -29),
        //     });
        //     autocomplete.addListener("place_changed", () => {
        //       infowindow.close();
        //       marker.setVisible(false);
        //       const place = autocomplete.getPlace();
    
        //       if (!place.geometry || !place.geometry.location) {
        //         // User entered the name of a Place that was not suggested and
        //         // pressed the Enter key, or the Place Details request failed.
        //         window.alert(
        //           "No details available for input: '" + place.name + "'"
        //         );
        //         return;
        //       }
    
        //       // If the place has a geometry, then present it on a map.
        //       if (place.geometry.viewport) {
        //         map.fitBounds(place.geometry.viewport);
        //       } else {
        //         map.setCenter(place.geometry.location);
        //         map.setZoom(17);
        //       }
        //       marker.setPosition(place.geometry.location);
        //       marker.setVisible(true);
        //       infowindowContent.children["place-name"].textContent = place.name;
        //       infowindowContent.children["place-address"].textContent =
        //         place.formatted_address;
        //       infowindow.open(map, marker);
        //     });
    
        //     // Sets a listener on a radio button to change the filter type on Places
        //     // Autocomplete.
        //     function setupClickListener(id, types) {
        //       const radioButton = document.getElementById(id);
        //       radioButton.addEventListener("click", () => {
        //         autocomplete.setTypes(types);
        //         input.value = "";
        //       });
        //     }
        //     setupClickListener("changetype-all", []);
        //     setupClickListener("changetype-address", ["address"]);
        //     setupClickListener("changetype-establishment", ["establishment"]);
        //     setupClickListener("changetype-geocode", ["geocode"]);
        //     biasInputElement.addEventListener("change", () => {
        //       if (biasInputElement.checked) {
        //         autocomplete.bindTo("bounds", map);
        //       } else {
        //         // User wants to turn off location bias, so three things need to happen:
        //         // 1. Unbind from map
        //         // 2. Reset the bounds to whole world
        //         // 3. Uncheck the strict bounds checkbox UI (which also disables strict bounds)
        //         autocomplete.unbind("bounds");
        //         autocomplete.setBounds({
        //           east: 180,
        //           west: -180,
        //           north: 90,
        //           south: -90,
        //         });
        //         strictBoundsInputElement.checked = biasInputElement.checked;
        //       }
        //       input.value = "";
        //     });
        //     strictBoundsInputElement.addEventListener("change", () => {
        //       autocomplete.setOptions({
        //         strictBounds: strictBoundsInputElement.checked,
        //       });
    
        //       if (strictBoundsInputElement.checked) {
        //         biasInputElement.checked = strictBoundsInputElement.checked;
        //         autocomplete.bindTo("bounds", map);
        //       }
        //       input.value = "";
        //     });
        // }
    }, [props])


    return (
        <div className="section" id="Getdirection" ref={getDirectionRef}>
            <div className="container">
                <div className="sec_title_group">
                    <h1 className="section_title">GET DIRECTION</h1>
                </div>

                <div className="description">We will communicate with you and looking forward to hearing from you.
                </div>


                <div className="direction_form">

                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text" id="btnGroupAddon2"><span><i
                                        className="fas fa-map-marker-alt"></i></span> FROM
                                    </div>
                                </div>
                                <input type="text" className="form-control" 
                                    placeholder="Enter your Address"
                                    aria-label="Input group example" 
                                    aria-describedby="btnGroupAddon2" 
                                    value={fromAddress}
                                    onChange={(e) => setfromAddress(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text" id="btnGroupAddon2"><span><i
                                        className="fas fa-map-marker-alt"></i></span> TO
                                    </div>
                                </div>
                                <input type="text" className="form-control"
                                       placeholder={toAddress}
                                       aria-label="Input group example" 
                                       aria-describedby="btnGroupAddon2" 
                                       value={toAddress}
                                       onChange={(e) => settoAddress(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-12">
                            <button type="submit" className="btn_green_cal" onClick={submitHandler}>GET DIRECTION</button>
                        </div>

                    </div>

                </div>

                <div className="google_map" id="google_map">
                    

                    <iframe
                        src={`https://maps.google.com/maps?q=${encodeURIComponent(orgAdrress)}&output=embed`}
                        width="100%" height="450" frameBorder="0" allowFullScreen=""></iframe>
                </div>


            </div>
        </div>
    );
}

export default withRouter(GetDirection);