import React, { useState, useEffect } from 'react';
import BreadCrumbSection from "../BreadCrumbSection/BreadCrumbSection";
import {Link} from "react-router-dom";
import HomePageWeeklyProgSection from "../HomePageWeeklyProgSection/HomePageWeeklyProgSection";
import ServicesList from "../ServicesList/ServicesList";
import Socialicons from "../Socialicons/Socialicons";
import RecentNews from "../RecentNews/RecentNews";
import Helmet from "react-helmet";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import LatestEventsSidebar from "../Common/LatestEventsSidebar.js"
import LatestVideosSidebar from "../Common/LatestVideosSidebar.js"
import { getFaqs, getOrganization } from "../Home/apis";
import { serialize } from "../../utils/markdowntoreact";
import { VideoPlayerModal } from "master-react-lib";


import Ad336X280 from '../Ad336X280/Ad336X280'

const orgId = process.env.REACT_APP_ORGANIZATION_ID;

export default function Faq(props) {
    const [faqs, setfaqs] = useState([])
    const [searchRes, setsearchRes] = useState([])
    const [search, setsearch] = useState("")
    const [startpage, setstartpage] = useState(0)
    const [endpage, setendpage] = useState(10)
    const [page, setpage] = useState(1)
    const [showFeaturePages, setShowFeaturePages] = useState({});
    const [videoId, setVideoId] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [facebookUrl, setFacebookurl] = useState('');
    const [youtubeUrl, seYoutubeUrl] = useState('');

    
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            try {
                const ordData = await getOrganization(orgId);
                const orgSocials = ordData.data?.body?.data?.orgSocials
                    setFacebookurl(orgSocials.facebook);
                    seYoutubeUrl(orgSocials.youtube)
                if(ordData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages?.faqs?.status === "inactive") {
                    props.history.push("/");
                } else {
                    setShowFeaturePages(ordData?.data?.body?.data?.enabledIntegrations?.deenweb?.pages);
                    const resAnn = await getFaqs(orgId)
                    let resData = resAnn?.data?.body?.data;
                    if(resData.length > 0) {
                        resData = resData.sort((a, b) => a.order - b.order);
                    }
                    setfaqs(resData);
                    setsearchRes(resData);
                }
              } catch (err) {
                console.log(err)
              }
        }
        getData();
    }, [])

        const faqsData = searchRes?.length ? searchRes.slice(startpage, endpage).map((el, i) => {
        let x = el.answer && JSON.parse(el.answer);
        let editor;
        if(x) {
         editor = { children: [ ...x ] }
        }

        const description = el.answer && el.answer?.includes('"type":') ? serialize(editor) : el.answer;

        return <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {el.question}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p dangerouslySetInnerHTML={{ __html: description }}>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        }) : null;

        const handleSearch = (val) => {
            setsearch(val)
            if (val) {
                setsearchRes(faqs.filter((faq) => faq.question.toLowerCase().match(val)));
            } else {
                setsearchRes(faqs);
            }
        }

        const handlePagination = (index) => {
            // i === 0 ? 0 : i * 10, (i + 1) * 10, i + 1
            const startIndex = index === 0 ? 0 : index * 10;
            const endIndex = (index + 1) * 10;
            const currentPage = index + 1;
            setstartpage(startIndex)
            setendpage(endIndex)
            setpage(currentPage)
            console.log(startIndex, endIndex, currentPage, "page")
        }

        const getVideoId = (videoId) => {
            if (videoId) {
                setVideoId(videoId)
                setShowVideo(true)
            }
    
        }

        const faqProps = {
            facebook : {facebookUrl},
            youtube: {youtubeUrl}
        }
        return (
            <div>
                <div className="fixed_bg"></div>
            <div className="wrapper body">
                {/*------- Update website title -------*/}
                <Helmet>
                    <title>Darul Jannah - FAQs | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="FREQUENTLY ASKED QUESTION" b_link="FAQs"/>

                {/*-------------- faqs AND RECENT NEWS ------------*/}
                <div className="section">
                    <div className="container">
                        <div className="news_content">
                            <div className="row">

                                <div className="col-lg-8 col-md-12">
                                    <div className="sec_title_group">
                                        <h1 className="section_title">SOME OF YOUR QUESTIONS</h1>
                                    </div>

                                    <div className="faq_search">

                                        <div className="input-group">
                                            <span className="search_icon"><i className="fa fa-search"></i></span>
                                            <input type="text" className="form-control search_fld"
                                                   placeholder="Type Keyword" onChange={(e) => handleSearch(e.target.value)} value={search} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-secondary" type="button">SEARCH</button>
                                                </div>
                                        </div>

                                    </div>

                                    <div className="faq">


                                        <Accordion className="faqitem" allowZeroExpanded={true}>
                                        {faqsData}
                                            {/* <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        What harsh truths do you prefer to ignore?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                        Exercitation in fugiat est ut ad ea cupidatat ut in
                                                        cupidatat occaecat ut occaecat consequat est minim minim
                                                        esse tempor laborum consequat esse adipisicing eu
                                                        reprehenderit enim.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionItemHeading >
                                                    <AccordionItemButton>
                                                        Is free will real or just an illusion?
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p>
                                                        In ad velit in ex nostrud dolore cupidatat consectetur
                                                        ea in ut nostrud velit in irure cillum tempor laboris
                                                        sed adipisicing eu esse duis nulla non.
                                                    </p>
                                                </AccordionItemPanel>
                                            </AccordionItem> */}
                                        </Accordion>
                                        


                                        {faqs?.length > 10 ? <div className="pagination_container">
                                            <nav>
                                                <ul className="pagination">
                                                    <li className="page-item disabled" onClick={() => page <= 1 ? null : handlePagination(page - 2)}>
                                                        <span className="page-link" tabIndex="-1"><i
                                                            className="fas fa-angle-left"></i></span>
                                                    </li>

                                                    {Array(Math.ceil(faqs?.length / 10) * 1).fill().map((faq, i) => {
                                                        return <li className={`page-item ${page === i + 1 ? "active" : ""}`} onClick={() => handlePagination(i)}>
                                                        <span className="page-link">{i + 1}
                                                            <span className="sr-only">(current)</span>
                                                        </span>
                                                    </li>
                                                    })}
                                                    
                                                    <li className="page-item disabled" onClick={() => page >= Math.ceil(faqs?.length / 10) * 1 ? null : handlePagination(page)}>
                                                        <span className="page-link"><i
                                                            className="fas fa-angle-right"></i></span>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div> : null }


                                    </div>


                                </div>
                                {/*----------------------- SIDEBAR START ------------------------*/}
                                <div className="col-lg-4 col-md-12">
                                    {showFeaturePages?.videos?.enablePage &&
                                        <>
                                            <div className="sec_title_group">
                                                <h2 className="section_title">RECENT VIDEOS</h2>
                                            </div>
                                                    <LatestVideosSidebar getVideoId={getVideoId}/>
                                                    <VideoPlayerModal
                                                        isOpen={showVideo}
                                                        onClose={() => setShowVideo(false)}
                                                        vidoeID={videoId}
                                                    />
                                        </>
                                    }

                                    <div className="sidebar_section">
                                        <div className="sidebar_title">
                                            <h2>GET CONNECTED</h2>
                                        </div>
                                        <Socialicons orgSocials = {faqProps} />
                                    </div>   
                                    {showFeaturePages?.events?.enablePage &&            
                                        <LatestEventsSidebar />
                                    }

                                    <Ad336X280/>


                                </div>
                                {/*------------------- SIDEBAR END -------------------*/}


                            </div>

                        </div>


                    </div>
                </div>

                {/*----------------------- WEEKLY PROGRAM SECTION ------------------ */}
                <HomePageWeeklyProgSection />
                {/*----------------------- Services List ----------------------------*/}
                {showFeaturePages?.services?.enablePage &&
                    <ServicesList />
                }

                {/* <div className="section">
                    <Ad728X90/>
                </div> */}


                <div className="footer_space"></div>


            </div>
            </div>
        );
}
