import React from 'react';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
class ReserveYourToday extends React.Component {
    render(){
        return (
            <div className="section">
                <div className="reserve_section">
                    <div className="container">
                        <div className="row">
                            <h1>RESERVE YOURS TODAY</h1>
                            <p>And the life of this world is nothing but play and amusement. But far better is the house
                                in the hereafter for those who are Al-Muttaqun (the pious). Will you not then
                                understand?
                                (Quran, Al-An’aam: 32)</p>
                            <p>(Quran 45:26)</p>
                            <div className="btn_container">
                                <a className="button btn_outline" href="#">DOWNLOAD FORM</a>
                                <a className="arrow_btn" href="#">ONLINE REGISTRATION <ArrowRightAltIcon /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReserveYourToday;