import React, { useEffect, useState, useRef } from "react";
import TabList from "./TabList";

import Imgs1 from '../../Assets/imgs/DAILY_AND_JUMU.png'
import Imgs2 from '../../Assets/imgs/RAMADAN.png'
import Imgs3 from '../../Assets/imgs/EID_PRAYERS.png'
import Imgs4 from '../../Assets/imgs/player.png';

import { getServices } from "../Home/apis";
import { Link, withRouter } from 'react-router-dom';
import { serialize } from "../../utils/markdowntoreact";
import moment from 'moment';

const orgId = process.env.REACT_APP_ORGANIZATION_ID;


function OurServicesAboutUs(props) {
    const [services, setservices] = useState([])
    const [tab, settab] = useState(1)
    const serviceRef = useRef();

    useEffect(() => {
        if (props?.scrollToTab && (props?.scrollToTab === 1 || props?.scrollToTab !== tab) ) {
            settab(props?.scrollToTab)
            serviceRef.current.scrollIntoView({ behavior: "smooth"});
        }

        const getData = async () => {
            try {
                let resService = await getServices(orgId, "deenweb")
                resService = resService?.data?.body?.data ?? [];
                setservices(resService)

            } catch (err) {
                console.log(err)
            }
        }
        getData();
    }, []);

    useEffect(() => {
        if (props?.scrollToTab && (props?.scrollToTab === 1 || props?.scrollToTab !== tab) ) {
            settab(props?.scrollToTab)
            serviceRef.current.scrollIntoView({ behavior: "smooth"});
        }
    }, [props]);

    console.log(tab, "tab")

    const servicesData = services?.length ? services.map(({ service }, i) => {
        let x = service?.description && JSON.parse(service?.description);
        let editor;
        if (x) {
            editor = { children: [...x] }
        }

        const description = service?.description && service?.description?.includes('"type":') ? serialize(editor) : service?.description;

        return <div key={i} className={`tab-pane ${service?._id === tab || i + 1 === tab ? "active show" : ""}`} id={`dashboard-${i + 1}`}>
            <h2>{service?.name}</h2>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
    }) : null;

    const refStyle = {
        width:150,
        height:200,
      };
    return (

        
        <div className="container" >
            <div ref={serviceRef}></div>
            <div className="sec_title_group" >
                <h1 className="section_title">OUR SERVICES FOR THE COMMUNITY</h1>
            </div>

            <div className="listing_tab" >
                <TabList services={services} tab={tab} />
                <div className="tab_container">
                    <div className="tab-content">
                        {servicesData}
                    </div>
                </div>

                <div className="clearfix" ></div>
                
            </div>

        </div >
    );
}

export default withRouter(OurServicesAboutUs);


