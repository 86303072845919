import React, { useState, useEffect } from 'react';
import { getReminders } from "../Home/apis";
import { serialize } from "../../utils/markdowntoreact";

const orgId = process.env.REACT_APP_ORGANIZATION_ID;


export default function AboutUsAayatSlider() {
    const [reminders, setreminders] = useState([])
    const [news, setnews] = useState([])
    const [showArrows, setShowArrows] = useState(true)


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const getData = async () => {
            try {
                let resReminders = await getReminders(orgId, "deenweb")
                resReminders = resReminders?.data?.body?.data ?? [];
                resReminders = resReminders.filter(({status, publishTo, publishFrom}) => status === "Public" && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date()));
            
                // resReminders = resReminders?.length ? resReminders.filter((el) => el.featured === true) : []
                // console.log("resReminders", resReminders)
                setreminders(resReminders)
                setShowArrows(resReminders.length > 1 ? true : false)
    
              } catch (err) {
                console.log("here", err)
              }
        }
        getData();
    }, []);

    return  (reminders.length) > 0 ? 
        (
        <div className="col-md-8">


            <div className="becom_member_container">
                <div className="pattren_right"><img src="assets/img/ms_pattren.png" /></div>


                <div className="becom_member_content">

                    <div id="multi-item-example" className="carousel slide carousel-multi-item"
                         data-ride="carousel">
                        <div className="carousel-inner" role="listbox">

                            {reminders?.length ? reminders?.map(({reminder}, i) => {
                                let x = reminder?.description && JSON.parse(reminder?.description);
                                let editor;
                                if(x) {
                                    editor = { children: [ ...x ] }
                                }
                        
                                const description = reminder?.description && reminder?.description?.includes('"type":') ? serialize(editor) : reminder?.description;
                                
                                return <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
                                <h1>{reminder.title}</h1>
                                <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                {/* <div className="rafrence">(Surat Al-Hashr 59:10)</div> */}
                            </div>
                            }) : null}

                        </div>
                    </div>

                    {showArrows && <div className="slider_arrow green_box_arrow">
                        <div className="controls-top">
                            <a className="btn-floating prv" href="#multi-item-example" data-slide="prev"><i className="fa fa-chevron-left"></i></a>
                            <a className="btn-floating next" href="#multi-item-example" data-slide="next"><i className="fa fa-chevron-right"></i></a>
                        </div>
                    </div> }

                </div>


            </div>

        </div>
    ) : null
}
