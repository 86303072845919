import React from 'react';
import Helmet from "react-helmet";
import BreadCrumbSection from "../../BreadCrumbSection/BreadCrumbSection";

class GraveyardForm extends React.Component {
    render(){
        return (
            <div className="wrapper">

                <Helmet>
                    <title>Darul Jannah - GraveYard Form | Powered by DEENCONNECT</title>
                </Helmet>
                {/*-------------- BREADCRUMB SECTION ----------------*/}
                <BreadCrumbSection title="RESERVE YOURS TODAY" b_link="REGISTRATION"/>
                {/*-------------- FORM STARTED ----------------------*/}
                <div className="page_content">
                    <p className="gv_content">
                        And the life of this world is nothing but play and amusement. But far better is the house in
                        the hereafter for those who are Al-Muttaqun (the pious). Will you not then understand?
                        <span>(Quran, Al-An’aam: 32)</span>
                    </p>
                </div>

                <div className="container gv_layout_setup gv_form_main_layout mb-lg-5">

                    <div className="gv_form_dcr">Please download the form from the link below to fill and submit it in
                        person.
                    </div>


                    <div className="download_btn">
                        <button className="btn_submit">Download Form</button>
                    </div>


                    <div className="or_line"><span>OR</span></div>


                    <div className="form_title">
                        <h1>REGISTER ONLINE</h1>
                        <p>Please Fill in the Form Below to Request for Graveyard</p>
                    </div>


                    <form className="gv_form row">

                        <div className="row gv_form_layout">


                            <div className="col-md-4 col-lg-4">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                      <input type="text" className="form-control v_error" id="label-name" placeholder="Enter your first name" required />
                                      <label htmlFor="label-name">First Name</label>
                                      <p className="v_error_msg">First Name Field is required</p>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                      <input type="text" className="form-control" id="label-name1" placeholder="Enter your middle name" required />
                                      <label htmlFor="label-name1">Middle Name</label>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                      <input type="text" className="form-control" id="label-name2" placeholder="Enter your last name" required/>
                                      <label htmlFor="label-name2">Last Name</label>
                                    </span>
                                </div>
                            </div>

                        </div>

                        <div className="row gv_form_layout">
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group input-group">
                                  <span className="border-lable-flt">
                                      <input type="text" className="form-control" id="dob" placeholder="Enter your date of birth  " required />
                                    <label htmlFor="dob">Date of Birth</label>
                                    <i className="fas fa-calendar  form-control-feedback"></i>
                                  </span>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                                <div className="form-group input-group">
                                  <span className="border-lable-flt">
                                    <input type="email" className="form-control" id="email" placeholder="Enter your email" required />
                                    <label htmlFor="email">Email</label>
                                  </span>
                                </div>
                            </div>
                        </div>

                        <div className="row gv_form_layout">
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                        <input type="text" className="form-control" id="mobile" placeholder="Enter your mobile phone number" required />
                                      <label htmlFor="mobile">Mobile Phone</label>
                                    </span>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                      <input type="text" className="form-control" id="home_phone" placeholder="Enter your home phone" required />
                                      <label htmlFor="home_phone">Home Phone</label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="row gv_form_layout">
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group input-group">
                                <span className="border-lable-flt">
                                    <input type="text" className="form-control" id="street" placeholder="Enter your Street number" required />
                                  <label htmlFor="street">Street</label>
                                </span>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                      <input type="text" className="form-control" id="apartment" placeholder="Enter your apartment/floor" required />
                                      <label htmlFor="apartment">Apartment/Floor</label>
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className="row gv_form_layout">

                            <div className="col-md-4 col-lg-4">
                                <div className="form-group input-group bmd-form-group">
                                    <label className="form-group border-lable-flt">
                                        <select name="city" className="form-control custom-select">
                                            <option selected="">City</option>
                                        </select>
                                        <span>City</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group input-group bmd-form-group">
                                    <label className="form-group border-lable-flt">
                                        <select name="state" className="form-control custom-select">
                                            <option selected="">State</option>
                                        </select>
                                        <span>State</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                      <input type="text" className="form-control" id="zipcode" placeholder="Enter your Zip Code" required />
                                      <label htmlFor="zipcode">Zip Code</label>
                                    </span>
                                </div>
                            </div>

                        </div>

                        <div className="gv_form_sec_title">Emergency Contact Details</div>

                        <div className="row gv_form_layout">

                            <div className="col-md-4 col-lg-4">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                      <input type="text" className="form-control" id="f-name" placeholder="Enter your first name" required />
                                      <label htmlFor="f-name">First Name</label>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                      <input type="text" className="form-control" id="m-name1" placeholder="Enter your middle name" required />
                                      <label htmlFor="m-name1">Middle Name</label>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                      <input type="text" className="form-control" id="l-name2" placeholder="Enter your last name" required />
                                      <label htmlFor="l-name2">Last Name</label>
                                    </span>
                                </div>
                            </div>

                        </div>


                        <div className="row gv_form_layout">
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group input-group">
                                    <span className="border-lable-flt">
                                        <input type="text" className="form-control" id="mobile1" placeholder="Enter your mobile phone number" required />
                                      <label htmlFor="mobile1">Mobile Phone</label>
                                    </span>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6">
                                <div className="form-group input-group bmd-form-group">
                                    <label className="form-group border-lable-flt">
                                        <select name="relationship" className="form-control custom-select">
                                            <option selected="">Relationship to Applicant</option>
                                        </select>
                                        <span>Relationship</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="gv_form_sec_title">Graveyard Details</div>

                        <div className="row gv_form_layout counter_box">

                            <div className="col-md-4 col-lg-4">
                                <small>Number of Graves</small>
                                <div className="form-group input-group gv_form_counter_field">
                                    <input type='button' defaultValue='-' className='qtyminus' field='quantity'/>
                                    <input type='text' name='quantity' defaultValue='0' className='qty'/>
                                    <input type='button' defaultValue='+' className='qtyplus' field='quantity'/>
                                </div>
                            </div>

                            <div className="col-md-2 col-lg-2">
                                <br />
                                    <small>20% OFF</small>
                                    <p className="gv_form_off_pckg"><b>$2000 USD</b></p>
                            </div>

                            <div className="col-md-2 col-lg-2">
                                <p className="mt-lg-5 new_p"><b>$1600 USD</b></p>
                            </div>

                            <div className="col-md-4 col-lg-4">

                                <div className="gv_check_out_form_tbl">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="d1">Subtotal</td>
                                                <td className="d3">$2000</td>
                                            </tr>

                                            <tr>
                                                <td className="d1">Discount</td>
                                                <td className="d3">-$400</td>
                                            </tr>

                                            <tr>
                                                <td className="d1">Total</td>
                                                <td className="d3">$1600</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>


                                <div className="form_btn">
                                    <button className="btn_submit">Proceed to Checkout</button>
                                </div>


                            </div>

                        </div>


                    </form>
                </div>

                <div className="footer_space"></div>


            </div>
        );
    }
}

export default GraveyardForm;