import axios from 'axios';

const orgApiKey = process.env.REACT_APP_ORGANIZATION_API_KEY;
const orgBaseUrl = process.env.REACT_APP_BASEURL_DEV_API;

export function getEvents(id, application) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/dw-public/getEvents?orgId=${id}&application=${application}`, {
          headers: {
            token: orgApiKey
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function getReminders(id, application) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getReminders?orgId=${id}&application=${application}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getNews(id, application) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getNews?orgId=${id}&application=${application}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSliders(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getSliders?org=${id}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getVideos(id, application) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getVideos?orgId=${id}&application=${application}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getServices(id, application) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getServices?orgId=${id}&application=${application}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSalahWaqt(latLng) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getSalahWaqt?duration=1&w_method=&latitude=${latLng[0]}&longitude=${latLng[1]}&timezone=auto`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getFaqs(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getFaqs?orgId=${id}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getAnnouncements(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getAnnouncements?orgId=${id}&application=deenweb`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getOrganization(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getOrganization?orgId=${id}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getSalahs(id, website) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getSalahs?orgId=${id}&application=${website}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPromotions(id, application) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getPromotions?orgId=${id}&application=${application}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPhotos(id, application) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getPhotos?orgId=${id}&application=${application}`, {
        headers: {
          token: orgApiKey
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}