import React from 'react';
import { render } from "react-dom";

function EidOrganizer() {
    return (
        <div className="section">
            <div className="container">
                <div className="sec_title_group">
                    <h1 className="section_title">ORGANIZERS</h1>
                </div>

                <div className="row">

                    <div className="col-lg-4 col-md-4 col-sm-12">

                        <div className="org">
                            <h2 className="org_title">Darul Jannah Masjid</h2>
                            <p><i className="fas fa-map-marked-alt"></i> <span>6 Avenue C <br/> Brooklyn, NY 11218</span></p>
                            <p><i className="fas fa-phone-alt"></i> <span>(718) 451-6423 <br/>
                            (718) 451-6423</span></p>
                            <p><i className="fas fa-envelope"></i> <span>team@daruljannah.org</span></p>
                        </div>

                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">

                        <div className="org">
                            <h2 className="org_title">Baitul Mamur Masjid</h2>
                            <p><i className="fas fa-map-marked-alt"></i> <span>6 Avenue C <br/> Brooklyn, NY 11218</span></p>
                            <p><i className="fas fa-phone-alt"></i> <span>(718) 451-6423 <br/>
                            (718) 451-6423</span></p>
                            <p><i className="fas fa-envelope"></i> <span>team@daruljannah.org</span></p>
                        </div>

                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">

                        <div className="org border_none">
                            <h2 className="org_title">Brooklyn Islamic Center (BIC)</h2>
                            <p><i className="fas fa-map-marked-alt"></i> <span>6 Avenue C <br/> Brooklyn, NY 11218</span></p>
                            <p><i className="fas fa-phone-alt"></i> <span>(718) 451-6423 <br />
                            (718) 451-6423</span></p>
                            <p><i className="fas fa-envelope"></i> <span>team@daruljannah.org</span></p>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
}
export default EidOrganizer;